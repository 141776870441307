import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";


import Form from "app/components/form/Form";
import CircularProgress from "app/components/circularprogress/CircularProgress";
import { setOpenMenu } from 'app/store/menu/menuSlice';
import { Button } from '@mui/material';


import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import CustomerService from 'app/services/customer/customerService';
import TransactioncountsService from 'app/services/transactioncounts/transactioncountsService'
import TransactionsService from 'app/services/transaction/TransactionService';
import LogService from 'app/services/logs/logService';
import MessageService from "app/services/messsage/MessageService"
import { generateInvoicePdf } from "app/utils/pdf/PDF"
import "./payment.scss";

const NewPayment = () => {
    const dispatch = useDispatch();
    const isMobile = useSelector((state) => state.menu.isMobileDevice);
    const userData = useSelector(state => state.user.user.user);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [datacountnumber, setDatacountnumber] = useState({});
    const location = useLocation();
    const payment_methods = [{ "label": 'efectivo', value: "efectivo" }, { "label": 'trasnferencia', value: "trasnferencia" }]

    const [ordernumber, setOrderNumber] = useState('');
    const [datapayment, setDataPayment] = useState({
        "reg_number": "",
        "identification": "",
        "name": "",
        "limit_credit": 0,
        "send_phone": false,
        "mobile": "",
        "subtotal": 0,
        "total": 0,
        "discount": 0,
        "balance_before": 0,
        "balance_after": 0,
        "received": 0,
        "change_given": 0,
        "items": [],
        "create_pdf": true,
        "payment_method": "efectivo"
    });
    const columnsForm = [
        {
            field: "reg_number",
            type: "string",
            headerName: "# de cuenta",
            showFormNew: true,
            disabled: false,
            onChange: (e) => { handleChane(e, "reg_number") },
            onKeyDown: (e) => handleEnterKey(e, "customer"),
        },
        {
            field: "identification",
            type: "string",
            headerName: "Identificación",
            showFormNew: true,
            disabled: false,
            onChange: (e) => { handleChane(e, "identification") },
            onKeyDown: (e) => handleEnterKey(e, "customer"),
            renderExtra: (value, loadingButton) => {
                return (
                    <Button onClick={() => fetchCustomers(value)} className="button button-search" disabled={loadingButton}>
                        {loadingButton ? (
                            <CircularProgress className="CircularProgress" />
                        ) : (
                            <SearchIcon />
                        )}
                    </Button>
                );
            }
        },
        {
            field: "name",
            type: "string",
            headerName: "Nombre del cliente",
            showFormNew: true,
            disabled: false,
            onChange: (e) => { handleChane(e, "name") }
        },
        {
            field: "mobile",
            type: "string",
            headerName: "Número del celular",
            showFormNew: true,
            disabled: false,
            onChange: (e) => { handleChane(e, "mobile") }
        },
        {
            field: "send_phone",
            type: "boolean",
            headerName: "Enviar notificación al celular",
            showFormNew: true,
            disabled: false,
            onChange: (e) => { handleChane(e, "send_phone") }
        },

        {
            field: "payment_method",
            type: "select",
            headerName: "Método de pago",
            showFormNew: true,
            disabled: false,
            options: payment_methods
        }
        ,
        {
            field: "create_pdf",
            type: "boolean",
            headerName: "Generar factura del pago",
            showFormNew: true,
            disabled: true,
        },
        {
            field: "balance_before",
            type: "currency",
            headerName: "Saldo actual",
            showFormNew: true,
            disabled: true,
            onChange: (e) => { handleChane(e, "balance_before") }
        },
        {
            field: "total",
            type: "currency",
            headerName: "Valor a pagar:",
            showFormNew: true,
            disabled: false,
            onChange: (value, formData, setFormData) => {
                setFormData({ ...formData, balance_after: formData.balance_before - value, total: value });

            }
        },
        {
            field: "balance_after",
            type: "currency",
            headerName: "Nuevo saldo",
            showFormNew: true,
            disabled: true,
            onChange: (e) => { handleChane(e, "balance_after") }


        },
        {
            field: "received",
            type: "currency",
            headerName: "Recibe ",
            showFormNew: true,
            disabled: false,
            onChange: (value, formData, setFormData) => {
                setFormData({ ...formData, change_given: value - formData.total, received: value });

            }

        },
        {
            field: "change_given",
            type: "currency",
            headerName: "Devuelve",
            showFormNew: true,
            disabled: true,
            onChange: (e) => { handleChane(e, "change_given") }


        },


    ];


    const putOrderNumber = useCallback(async () => {

        try {
            const dataNumber = await TransactioncountsService.getAll();

            setOrderNumber("AB-" + (dataNumber[0]["payments_consecutive"] + 1).toString().padStart(5, '0'));
            setDatacountnumber({ "id": dataNumber[0]["id"], "field": "payments_consecutive", "number": dataNumber[0]["payments_consecutive"] + 1 })


            setIsLoading(false);

        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        if (isMobile) {
            dispatch(setOpenMenu(false));
        }
        const datapaymentStore = JSON.parse(localStorage.getItem("datapayment"))
        if (datapaymentStore) {
            setDataPayment(datapaymentStore)
        }

        async function updateOrderNumber() {
            setIsLoading(true);
            await putOrderNumber();

        }

        updateOrderNumber();
        localStorage.setItem('lastVisitedPage', location.pathname);
    }, [isMobile, putOrderNumber, dispatch, location]);


    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleEnterKey = async (e, type) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (e.target.value === "") {
                setSnackbarMessage('Debe agregar un valor'); // Asegúrate de que setSnackbarMessage reciba un valor adecuado
                setOpenSnackbar(true);
                return
            }
            const data = {
                "reg_number": e.target.value,
                "identification": e.target.value,
            }
            await fetchCustomers(data);

        }
    };


    const handleChane = (e, field_name) => {
        setDataPayment(prevState => {
            let updatedData = { ...prevState, [field_name]: e };
            return updatedData;
        });
    };


    const fetchCustomers = async (data) => {

        if (data["reg_number"] === "" && data["identification"] === "") {
            setSnackbarMessage('Debe agregar un valor de busqueda'); // Asegúrate de que setSnackbarMessage reciba un valor adecuado
            setOpenSnackbar(true);
            return
        }
        setLoadingButton(true);
        try {
            const dataCustomer = await CustomerService.getAll({
                "reg_number_or": data["reg_number"].toLowerCase(),
                "identification_or": data["identification"].toLowerCase()
            });

            if (Array.isArray(dataCustomer) && dataCustomer.length > 0) {
                setDataPayment(prevData => {
                    const updatedData = { ...prevData };

                    dataCustomer.forEach(customer => {
                        Object.keys(customer).forEach(key => {
                            if (key !== "items") {

                                if (key === "balance") {
                                    updatedData["balance_before"] = customer[key];
                                }
                                else {
                                    updatedData[key] = customer[key];
                                }

                            }
                        });

                        if (Array.isArray(customer.items) && customer.items.length > 0) {
                            updatedData.items = [
                                ...updatedData.items,
                                ...customer.items
                            ];
                        }
                    });
                    updatedData.balance_after = updatedData.balance_before + updatedData.total
                    return updatedData;
                });
            }
            else {

                setSnackbarMessage('No se encontro ningun dato'); // Asegúrate de que setSnackbarMessage reciba un valor adecuado
                setOpenSnackbar(true);

            }

        } catch (error) {
            console.error(error);
        } finally {
            setLoadingButton(false);
        }
    };

    const submit = async (data) => {
        let payment_method = data['payment_method']

        if (typeof data['payment_method'] === 'object') {
            payment_method = data['payment_method']["value"]
        }

        if (Number(data["total"]) <= 0) {
            return "Debes ingresar un valor válido como pago"
        }

        if (Number(data["change_given"]) < 0) {
            return "El valor a devolver no puede ser negativo"
        }

        // Si es una venta de credito se debe validar primero el cliente 
        let dataCustomer;

        dataCustomer = await CustomerService.getAll({ "reg_number": datapayment["reg_number"] });
        if (!dataCustomer) {
            return "El cliente no existe"
        }

        if (Number(data["balance_after"]) < 0) {
            return `El cliente ${data["reg_number"]} no puede quedar con saldo negativo. Error creando la factura ${ordernumber.toLowerCase()}`
        }

        let logs = [];
        let newDataCustomer = { "balance": data["balance_after"], "last_payment_amount": parseFloat(data["total"]) }
        if (userData.customers.update) {
            newDataCustomer = {
                "name": datapayment["name"],
                "identification": datapayment["identification"],
                "send_phone": datapayment["send_phone"],
                "mobile": datapayment["mobile"],
                "balance": data["balance_after"],
                "last_payment_amount": parseFloat(data["total"])
            }
        }

        // Se  actualiza el cliente 
        const response_customer = await CustomerService.put(dataCustomer[0]["id"], newDataCustomer);
        if (response_customer.status !== 200) {
            return response_customer
        }

        logs.push({
            "user": userData.username,
            "process": "update",
            "module": "customers",
            "code": datapayment["reg_number"].toLowerCase(),
            "name": datapayment["name"],
            "description": `Se agregó pago de ${parseFloat(data["total"]).toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 })} con factura ${ordernumber}. El nuevo saldo es de ${data["balance_after"].toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
        })


        if (datapayment["send_phone"]) {
            await MessageService.post({
                "total": data["total"],
                "reg_number": datapayment["reg_number"],
                "balance": data["balance_after"],
                "order_number": ordernumber,
                "type": "payment",
                "phone": "57" + datapayment["mobile"],
            })
        }

        const newTrasaction = {
            "number": ordernumber.toLowerCase(),
            "type": "payment",
            "sub_type": "abono",
            "reg_number": datapayment["reg_number"],
            "items": datapayment["items"],
            "total": data["total"],
            "user": userData.username.toLowerCase(),
            "received": data['received'],
            "change_given": data['change_given'],
            "discount": datapayment['discount'],
            "subtotal": datapayment['subtotal'],
            "customer_name": datapayment["name"].toLowerCase(),
            "identification": datapayment["identification"],
            "mobile": datapayment["mobile"],
            "status": "cerrada",
            "balance_before": data["balance_before"],
            "balance_after": data["balance_after"],
            "payment_method": payment_method,
            "document_url": ""
        }
        const response = await TransactionsService.post(newTrasaction)


        logs.push({
            "user": userData.username,
            "process": "create",
            "module": "transactions",
            "code": ordernumber.toLowerCase(),
            "name": datapayment.name,
            "description": `Se agregó pago ${ordernumber} con un total de ${parseFloat(data["total"]).toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
        })

        let data_count = {};
        data_count[datacountnumber["field"]] = datacountnumber["number"];

        await TransactioncountsService.put(datacountnumber["id"], data_count)

        LogService.postMany(logs)

        localStorage.removeItem('datapayment');

        if (data.create_pdf) {
            generateInvoicePdf(userData, newTrasaction, "Factura de pago")
        }
        return response
    }

    return (
        <div className="sale" >
            <div className="info">
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <div className="new-sale">
                        <div className="information-sale">
                            <div className="order-number">
                                <input
                                    type="text"
                                    value={ordernumber}
                                    className="form-control"
                                    disabled={true}
                                />
                            </div>


                        </div>


                        <div className="forms-new-sale">
                            <div className="right">


                                <Form
                                    data={datapayment}
                                    slug=""
                                    columns={columnsForm}
                                    submit={submit}
                                    page="payments"
                                    action="add"
                                    showLastActivites={false}
                                    loadingButton={loadingButton}
                                    showButtomSubmmit={true}

                                />
                            </div>

                        </div>



                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackbar}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                            <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%', marginTop: '100px' }}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>

                    </div>
                )}
            </div >
        </div >
    )
}

export default NewPayment