import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ user, tag, requiredPermission  }) => {

  const hasAccess = user?.user?.user?.[tag]?.[requiredPermission];

  return hasAccess ? (
    <Outlet />
  ) : (
    <Navigate to="/no-access" />
  );
};

export default ProtectedRoute;
