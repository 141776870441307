import AWS from 'aws-sdk';
import { useSelector } from 'react-redux';

const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
});

export const useUploadToS3 = () => {
    const userData = useSelector(state => state.user.user.user);

    const uploadToS3 = async (file, reg_number, ordernumber) => {
        try {
            const params = {
                Bucket: userData.bucket_name,
                Key: `${reg_number}_${ordernumber}`,
                Body: file,
                ContentType: file.type,
                ACL: 'public-read', // Establece el permiso para que sea accesible públicamente
            };
            const { Location } = await s3.upload(params).promise();
            return Location; // Devuelve la URL del archivo subido
        } catch (error) {
            console.error('Error uploading file:', error);
            throw new Error('Failed to upload file to S3');
        }
    };

    return uploadToS3;
};
