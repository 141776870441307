import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { setOpenMenu } from '../../store/menu/menuSlice';
import "./menu.scss";

const Menu = ({ user }) => {
  const menuOpen = useSelector((state) => state.menu.openMenu);
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.menu.isMobileDevice);

  const menuRef = useRef(null);
  const [dataUser, setDataUser] = useState(user);
  const [activeUrl, setActiveUrl] = useState(localStorage.getItem('lastVisitedPage') || '');



  useEffect(() => {
    if (user && user.user) {
      setDataUser(user.user.user);
    }
    const Url = localStorage.getItem('lastVisitedPage');
    if (Url){
      let baseUrl = Url.split("/").slice(0, 2).join("/");
      setActiveUrl(baseUrl);
    }

  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && isMobile) {
        dispatch(setOpenMenu(false));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, isMobile, dispatch]);

  const toggleMenu = () => {
    dispatch(setOpenMenu(!menuOpen));
  };

  const handleMenuClick = (url) => {
    let baseUrl = url.split("/").slice(0, 2).join("/");
    setActiveUrl(baseUrl);
    localStorage.setItem('lastVisitedPage', url); // Guarda la URL en localStorage
  };

  const menu = [
    {
      id: 2,
      title: "lists",
      listItems: [
        { id: 1, title: "Clientes", url: "/customers", icon: "/user.svg", tag: 'customers' },
        { id: 2, title: "Productos", url: "/items", icon: "/product.svg", tag: 'items' },
        { id: 3, title: "Ventas", url: "/sales", icon: "/order.svg", tag: 'sales' },
        { id: 4, title: "Pagos", url: "/payments", icon: "/payment.svg", tag: 'payments' },
        { id: 5, title: "Compras", url: "/purchases", icon: "/purchase.svg", tag: 'purchases' },

      ],
    },
    {
      id: 3,
      title: "general",
      listItems: [
        { id: 1, title: "Elements", url: "/", icon: "element.svg", tag: 'element' },
        { id: 2, title: "Notes", url: "/", icon: "note.svg", tag: 'note' },
        { id: 3, title: "Forms", url: "/", icon: "form.svg", tag: 'form' },
        { id: 4, title: "Calendar", url: "/", icon: "calendar.svg", tag: 'calendar' },
      ],
    },
    // Otras secciones...
  ];

  const hasPermission = (item) => {
    if (dataUser[item.tag] && dataUser[item.tag]["show"]) {
      return item;
    }
  };

  const filteredMenu = menu.map((section) => {
    const filteredListItems = section.listItems.filter((item) => hasPermission(item));
    return { ...section, listItems: filteredListItems };
  });

  return (
    <div className={`menu ${menuOpen ? '' : 'close'}`} ref={menuRef}>
      <div className="hamburger-icon" onClick={toggleMenu}>
        <ArrowBackIosIcon />
      </div>
      {filteredMenu.map((item) => (
        <div className="item" key={item.id} onClick={() => isMobile && toggleMenu()}>
          <span className="title">{item.title}</span>
          {item.listItems.map((listItem) => (
            <Link
              to={listItem.url}
              className={`listItem ${activeUrl === listItem.url ? 'active' : ''}`} // Aplica clase 'active'
              key={listItem.id}
              onClick={() => handleMenuClick(listItem.url)}
            >
              <img src={listItem.icon} alt="" />
              <span className="listItemTitle">{listItem.title}</span>
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Menu;
