import axios from 'axios';
import { BACKEND_URL } from "config";
import authService from 'app/services/auth/authService';


class ItemService {

    getAll = async (params = {}) => {
        const dataAccessToken = authService.getDataLocalStorage();
        const accessToken = dataAccessToken.accessToken;
        try {
            const headers = {
                "Authorization": `Bearer ${accessToken}`
            };
            const response = await axios.get(`${BACKEND_URL}/item/`, { headers,params });
            return response.data;
        } catch (error) {
            if (error.response) {
                const status = error.response.status;
                const detail = error.response.data.detail;

                if (status === 401) {
                    console.error('Error 401: No autorizado. Redirigiendo al inicio de sesión...');
                    authService.emit('onAutoLogout');
                    return null;
                }

                console.error('Error en la solicitud:', detail || 'No se proporcionaron detalles del error');
                return detail || 'Error desconocido';
            } else {
                console.error('Error en la solicitud:', error.message || 'Error desconocido');
                return error.message || 'Error desconocido';
            }
        }
    }

    get = async (id) => {
        const dataAccessToken = authService.getDataLocalStorage();
        const accessToken = dataAccessToken.accessToken;
        try {
            const headers = {
                "Authorization": `Bearer ${accessToken}`
            };
            const response = await axios.get(`${BACKEND_URL}/item/${id}/`, { headers });
            return response.data;
        } catch (error) {
            if (error.response) {
                const status = error.response.status;
                const detail = error.response.data.detail;

                if (status === 401) {
                    console.error('Error 401: No autorizado. Redirigiendo al inicio de sesión...');
                    authService.emit('onAutoLogout');
                    return null;
                }

                console.error('Error en la solicitud:', detail || 'No se proporcionaron detalles del error');
                return detail || 'Error desconocido';
            } else {
                console.error('Error en la solicitud:', error.message || 'Error desconocido');
                return error.message || 'Error desconocido';
            }
        }
    };

    post = async (data) => {
        const dataAccessToken = authService.getDataLocalStorage();
        const accessToken = dataAccessToken.accessToken;
        try {
            const headers = {
                "Authorization": `Bearer ${accessToken}`
            };
            const response = await axios.post(`${BACKEND_URL}/item/`, data, { headers });
            return response;
        } catch (error) {
            if (error.response) {
                const status = error.response.status;
                const detail = error.response.data.detail;

                if (status === 401) {
                    console.error('Error 401: No autorizado. Redirigiendo al inicio de sesión...');
                    authService.emit('onAutoLogout');
                    return null;
                }

                console.error('Error en la solicitud:', detail || 'No se proporcionaron detalles del error');
                return detail || 'Error desconocido';
            } else {
                console.error('Error en la solicitud:', error.message || 'Error desconocido');
                return error.message || 'Error desconocido';
            }
        }
    };

    put = async (id=null, data, bulk_write=false) => {
        const dataAccessToken = authService.getDataLocalStorage();
        const accessToken = dataAccessToken.accessToken;
        try {
            const headers = {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            };
            const response = await axios.put(`${BACKEND_URL}/item/${id}/${bulk_write}/`, data, { headers });
            return response;
        } catch (error) {
            if (error.response) {
                const status = error.response.status;
                const detail = error.response.data.detail;

                if (status === 401) {
                    console.error('Error 401: No autorizado. Redirigiendo al inicio de sesión...');
                    authService.emit('onAutoLogout');
                    return null;
                }

                console.error('Error en la solicitud:', detail || 'No se proporcionaron detalles del error');
                return detail || 'Error desconocido';
            } else {
                console.error('Error en la solicitud:', error.message || 'Error desconocido');
                return error.message || 'Error desconocido';
            }
        }
    };

    delete = async (id) => {
        const dataAccessToken = authService.getDataLocalStorage();
        const accessToken = dataAccessToken.accessToken;
        try {
            const headers = {
                "Authorization": `Bearer ${accessToken}`
            };
            const response = await axios.delete(`${BACKEND_URL}/item/${id}/`, { headers });
            return response;
        } catch (error) {
            if (error.response) {
                const status = error.response.status;
                const detail = error.response.data.detail;

                if (status === 401) {
                    console.error('Error 401: No autorizado. Redirigiendo al inicio de sesión...');
                    authService.emit('onAutoLogout');
                    return null;
                }

                console.error('Error en la solicitud:', detail || 'No se proporcionaron detalles del error');
                return detail || 'Error desconocido';
            } else {
                console.error('Error en la solicitud:', error.message || 'Error desconocido');
                return error.message || 'Error desconocido';
            }
        }
    };

}
const instance = new ItemService();
export default instance;