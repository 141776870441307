import JsBarcode from 'jsbarcode'
import { jsPDF } from 'jspdf'

export const generateBarcodeAndDownload = (barcodeData) => {
    // Crear un elemento canvas temporal
    const canvas = document.createElement('canvas')

    // Dimensiones deseadas en milímetros
    const widthMM = 50
    const heightMM = 50

    // Convertir mm a puntos (1 mm = 2.83465 puntos)
    const widthPt = widthMM
    const heightPt = heightMM

    // Calcular las dimensiones del canvas (aumentamos la resolución para mejor calidad)

    canvas.width = widthMM
    canvas.height = heightMM
    // Generar el código de barras
    JsBarcode(canvas, barcodeData, {
        format: "CODE128",
        width: 2,
        height: heightMM, // Dejamos espacio para el texto
        displayValue: true,
        fontSize: 15,
        textMargin: 2,
        margin: 0
    })

    // Crear el PDF (tamaño A4)
    const pdf = new jsPDF()

    // Posicionar el código de barras en la esquina superior izquierda
    // Dejamos un pequeño margen de 5mm desde los bordes
    const x = 0
    const y = 0

    // Añadir la imagen al PDF
    const imgData = canvas.toDataURL('image/png')
    pdf.addImage(imgData, 'PNG', x, y, widthPt, heightPt)

    // Descargar el PDF
    pdf.save(barcodeData + '.pdf')
}