import React, { useEffect, useState, useRef, useCallback } from 'react';
import { BrowserMultiFormatReader } from '@zxing/browser';

const BarcodeScanner = ({ setBarcode }) => {
  const videoRef = useRef(null);
  const codeReaderRef = useRef(null);
  // const [isScanning, setIsScanning] = useState(false);
  // const [error, setError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const playPromiseRef = useRef(null); // Para manejar la promesa de reproducción del video

  const startScanner = useCallback( async() => {
    try {
      if (codeReaderRef.current || !videoRef.current) return;

      const codeReader = new BrowserMultiFormatReader();
      codeReaderRef.current = codeReader;

      // setIsScanning(true);

      // Obtener acceso a la cámara trasera
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' },
      });

      if (videoRef.current) {
        // Verificar si el video ya está asignado a una fuente de medios
        if (videoRef.current.srcObject !== stream) {
          videoRef.current.srcObject = stream;
        }

        // Asegurar que el video no intente reproducirse varias veces
        if (!isPlaying && playPromiseRef.current === null) {
          playPromiseRef.current = videoRef.current.play().then(() => {
            setIsPlaying(true);
            playPromiseRef.current = null;
          }).catch((e) => {
            if (e.name !== "AbortError") {
              console.error('Error al reproducir el video:', e);
              // setError('Error al reproducir el video');
            }
            playPromiseRef.current = null;
          });
        }

        // Escanear el código de barras
        codeReader.decodeFromVideoDevice(null, videoRef.current, (result, err) => {
          if (result) {
            setBarcode(result.getText());
            // console.log(`Código escaneado: ${result.getText()}`);
          } else if (err && err.name !== 'NotFoundException') {
            console.error('Error de escaneo:', err);
            // setError('Error de escaneo');
          }
        });
      }
    } catch (err) {
      console.error('Error iniciando el escáner:', err);
      // setError('Error iniciando el escáner');
      stopScanner();
    }
  }, [isPlaying, setBarcode]);

  const stopScanner = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null; // Limpiar el objeto srcObject
      setIsPlaying(false);
    }

    if (codeReaderRef.current) {
      codeReaderRef.current = null;
    }

    // setIsScanning(false);
  };

  useEffect(() => {
    startScanner();

    return () => {
      stopScanner();
    };
  }, []);

  return (
    <div>
      <h2  style={{ color:"white"}}>Escanear código de barras</h2>
      <video
        ref={videoRef}
        style={{ width: '100%', border: '5px solid green', color:"white"}}
        playsInline
        muted
      />
      {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
    </div>
  );
};

export default BarcodeScanner;
