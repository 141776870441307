import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import DataTable from "app/components/dataTable/DataTable";
import LoadingCircularProgress from "app/components/circularprogress/CircularProgress";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { CircularProgress } from '@material-ui/core';

import CustomerService from 'app/services/customer/customerService';
import LogService from 'app/services/logs/logService';
import { setOpenMenu } from 'app/store/menu/menuSlice';

import "./customers.scss";

const Customers = () => {

  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.menu.isMobileDevice);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userData = useSelector(state => state.user.user.user);
  const [reloading, setReloading] = useState(false);
  const [loadingButton, setLoadingButtons] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const columns = [
    {
      field: "reg_number",
      type: "string",
      headerName: "# de cuenta",
      showTable: true,
      showFormEdit: true,
      disabled: true,
      Cell: ({ value }) => {
        if (typeof value === 'string' && value.length > 0) {
          return value.charAt(0).toUpperCase() + value.slice(1);
        } else {
          return '';
        }
      }
    },
    {
      field: "identification",
      type: "number",
      headerName: "Identificación",
      showForm: true,
      showTable: true,
      showFormEdit: true,
      disabled: false,
    },
    {
      field: "name",
      type: "string",
      headerName: "Nombre",
      showForm: true,
      showTable: true,
      showFormEdit: true,
      disabled: false,
    },
    {
      field: "birthdate",
      type: "datetime",
      headerName: "Fecha de nacimiento",
      width: 200,
      showForm: true,
      showTable: true,
      showFormEdit: true,
      disabled: false,
    },
    {
      field: "mobile",
      type: "number",
      headerName: "# del celular",
      width: 100,
      showForm: true,
      showTable: true,
      showFormEdit: true,
      disabled: false,
    },
    {
      field: "balance",
      type: "number",
      headerName: "Saldo actual",
      showForm: false,
      showTable: true,
      showFormEdit: true,
      disabled: true,
      Cell: ({ value }) => {
        if (typeof value === 'number') {
          return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 });
        } else {
          return 0;
        }
      }
    },
    {
      field: "last_payment_date",
      type: "datetime",
      headerName: "Fecha del último pago",
      showForm: false,
      showTable: true,

    },
    {
      field: "last_payment_amount",
      type: "number",
      headerName: "Valor del último pago",
      showForm: true,
      showTable: true,
      showFormEdit: true,
      disabled: false,
      Cell: ({ value }) => {
        if (typeof value === 'number') {
          return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 });
        } else {
          return 0;
        }
      }
    },
    {
      field: "limit_credit",
      type: "number",
      headerName: "Cupo de crédito",
      showForm: true,
      showTable: true,
      showFormEdit: true,
      disabled: false,
      Cell: ({ value }) => {
        if (typeof value === 'number') {
          return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 });
        } else {
          return 0;
        }
      }
    },
    {
      field: "address",
      type: "string",
      headerName: "Direccion",
      width: 0,
      showForm: true,
      showTable: false,
    },
    {
      field: "send_phone",
      type: "boolean",
      headerName: "Enviar al celular",
      width: 0,
      showForm: true,
      showTable: false,
    },
    {
      field: "bu",
      type: "string",
      headerName: "",
      width: 70,
      showForm: false,
      showTable: true,
      Cell: ({ row }) => (
        <div className="buttons" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
          {
            userData.customers.update ?
              <Link to={`/customers/${row.original.id}`}>
                <EditIcon className="small-icon buttons-edit" />
              </Link>
              :
              ""
          }

          {
            loadingButton[row.original.id] ?
              <CircularProgress />
              :
              userData.customers.delete ?
                <Button
                  className="buttons-deleted"
                  color="secondary"
                  onClick={() => {
                    setSelectedCustomerId(row.original);
                    setDialogOpen(true);
                  }}
                >
                  <DeleteIcon className="small-icon" />
                </Button>
                :
                ""
          }
        </div>
      ),

    }

  ];


  useEffect(() => {
    setIsLoading(true)
    if (isMobile) {
      dispatch(setOpenMenu(false));
    }
    const fetchCustomers = async () => {
      try {
        const dataCustomer = await CustomerService.getAll({sort_field: "reg_number", sort_order:1});
        if (Array.isArray(dataCustomer)) {
          setCustomers(dataCustomer);
        }
        else {
          // Si no es un array, muestra un mensaje de error o maneja el caso
          console.error("La respuesta no es un array:", dataCustomer);
          setCustomers([]);  // Maneja la falta de datos de forma segura
        }
        setIsLoading(false)
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCustomers();
  }, [reloading, isMobile, dispatch]);



  const deleteCustomer = async (row) => {
    const saldo = parseFloat(row.balance);

    setLoadingButtons(prevState => ({
      ...prevState,
      [row.id]: true  // Establece el id específico a true
    }));

    try {
      const response = await CustomerService.delete(row.id);

      if (response.status !== 200) { // Corrección en la propiedad status
        setSnackbarMessage(response); // Asegúrate de que setSnackbarMessage reciba un valor adecuado
        setOpenSnackbar(true);
        setLoadingButtons(prevState => ({
          ...prevState,
          [row.id]: false
        }));
        return;
      }

      else {



        await LogService.post({
          "user": userData.username,
          "process": "delete",
          "module": "customers",
          "code": row.reg_number,
          "name": row.name,
          "description": `Se eliminó el cliente con el número de cuenta ${row.reg_number.toUpperCase()} y con saldo de ${saldo.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 })}`

        })

      }

      // Si la eliminación es exitosa, puedes recargar o hacer otra acción aquí
      setLoadingButtons(prevState => ({
        ...prevState,
        [row.id]: false  // Restablecer el indicador de carga
      }));
      setReloading(true)
    } catch (error) {
      console.error("Error eliminando el cliente:", error);
      setSnackbarMessage("Ocurrió un error al eliminar el cliente.");
      setOpenSnackbar(true);
      setLoadingButtons(prevState => ({
        ...prevState,
        [row.id]: false  // Restablecer el indicador de carga
      }));
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="customers">
      <div className="info">
        <h1>Clientes</h1>
        {userData.customers.create ?
          <Link to={"/customers/new"} className='button'>
            <PersonAddAltIcon />
          </Link>

          :
          ""
        }
      </div>
      {isLoading ? (
        <LoadingCircularProgress />
      ) : (
        <DataTable slug="customers" columns={columns} rows={customers} edit={userData.customers.update} delete={userData.customers.delete} />
      )}


      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Estás seguro de que deseas eliminar este cliente?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta acción no se puede deshacer. ¿Deseas continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => {
            deleteCustomer(selectedCustomerId);
            setDialogOpen(false);
          }} color="secondary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%', marginTop: '100px' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </div>
  );
};

export default Customers;
