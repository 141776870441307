import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Snackbar, Alert } from '@mui/material';
import { CircularProgress } from '@material-ui/core';
import Button from '@mui/material/Button';

import DatePicker from 'react-datepicker';
import Select from 'react-select';

import LogService from 'app/services/logs/logService';


import "react-datepicker/dist/react-datepicker.css";
import "./form.scss";


const Form = (props) => {


  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector(state => state.user.user.user);
  const nameButtom = props.action === 'edit' ? "Actualizar" : "Agregar";

  const formatCurrency = (value) => {
    if (!value) return '';
    return new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const unformatCurrency = (value) => {
    const unformattedValue = value.replace(/\D/g, '');
    return unformattedValue.replace(/[^0-9.-]+/g, '');
  };

  const handleFocus = (event) => {
    event.target.select();
  };


  const getDefaultValue = (type) => {
    switch (type) {
      case 'datetime': return "";
      case 'boolean': return false;
      case 'number': return "";
      case 'string': default: return "";

    }
  };

  const [formData, setFormData] = useState(
    props.columns.reduce((acc, column) => {
      acc[column.field] = props.data && props.data[column.field] !== undefined
        ? props.data[column.field]
        : getDefaultValue(column.type);
      return acc;
    }, {})
  );

  useEffect(() => {
    setFormData(
      props.columns.reduce((acc, column) => {
        // Obtén el valor original o el valor por defecto
        let value = props.data && props.data[column.field] !== undefined
          ? props.data[column.field]
          : getDefaultValue(column.type);

        // Aplica la transformación si la columna tiene la propiedad Cell
        if (column.Cell) {
          value = column.Cell({ value, row: props.data || {} });
        }

        acc[column.field] = value;
        return acc;
      }, {})
    );
  }, [props.data, props.columns]);


  const handleChange = (field, value) => {
    const column = props.columns.find(col => col.field === field);

    if (column && column.onChange) {

      // Si la columna tiene una función onChange, se llama aquí
      column.onChange(value, formData, setFormData);
    } else {
      // Si no, simplemente actualiza el campo como siempre
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    setIsLoading(true);

    const isEmptyField = Object.values(formData).some(
      (value) => value === null || value === '' || value === undefined
    );

    if (isEmptyField) {
      setSnackbarMessage('Todos los campos deben estar llenos');
      setOpenSnackbar(true);
      setIsLoading(false);
      return;
    }
    const response = await props.submit(formData);
    try {
      if (response.status === 200) {
        navigate(`/${props.page}`);
      }
      else {
        let mesage = ""
        if (Array.isArray(response)) {

          for (let msg in response) {
            mesage += `${response[msg]['msg']} ${response[msg]['loc'][1]}, `

          }
        }
        else {
          mesage = response.toString();
        }

        await LogService.post({
          "user": userData.username,
          "process": props.action,
          "module": props.page,
          "code": "error",
          "name": "error",
          "description": mesage
        })
        setSnackbarMessage(mesage);
        setOpenSnackbar(true);
        setIsLoading(false);
      }
    } catch (error) {
      setSnackbarMessage(error);
      setOpenSnackbar(true);
      setIsLoading(false);
      return;
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleFileChange = (field, event) => {
    const file = event.target.files[0]; // Obtenemos el archivo
    if (file) {
      setFormData({
        ...formData,
        [field]: file, // Almacena el archivo en el estado
      });
    }
  };

  return (
    <div className="form">
      <div className={`modal ${props.slug === 'cliente' ? 'cliente' : 'add'}`}>
        <div className="modal-form">
          {props.slug ? <h1>{props.action === 'edit' ? "Actualizar" : "Agregar nuevo"} {props.slug}</h1> : ""}
          <form onSubmit={handleSubmit}>
            {props.columns.filter(item => item.field !== "id" && item.field !== "img" && (props.action === 'edit' ? item.showFormEdit : item.showFormNew))
              .map(column => (
                <div className="item" key={column.field}>
                  <label>{column.type !== "boolean" ? column.headerName : ""}</label>
                  <div className="input-container">
                    {getInputField(column)}
                    {column.renderExtra && column.renderExtra(formData, props.loadingButton)}
                  </div>
                </div>
              ))}
            <button className={`button ${props.showButtomSubmmit ? "" : "button-hide"}`} type="submit" disabled={isLoading}>{isLoading ? <CircularProgress /> : nameButtom}</button>
          </form>
        </div>
        {props.showLastActivites && (
          <div className="activities">
            <h2>Actividad Reciente</h2>
            {props.activities?.length > 0 ? (
              <ul className="activity-ul">
                {props.activities.map(activity => (
                  <li className="activity-item" key={activity.id}>
                    <div>
                      <p>{activity.description}</p>
                      <time>{activity.creation_date}</time>
                      <br />
                      <time>{activity.user}</time>
                    </div>
                  </li>
                ))}
              </ul>
            ) : <p>No recent activities found.</p>}
          </div>
        )}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%', marginTop: '100px' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );

  function getInputField(column) {
    switch (column.type) {
      case "datetime":
        return (
          <DatePicker
            selected={formData[column.field]}
            onChange={(date) => handleChange(column.field, date)}
            dateFormat="dd/MM/yyyy"
            placeholderText={column.headerName}
            className="StyledDatePicker"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        );
      case "boolean":
        return (
          <div className="checkbox-container checkbox-container-edit" >
            <input
              type="checkbox"
              checked={formData[column.field]}
              onChange={(e) => handleChange(column.field, e.target.checked)}
              className="checkbox-input"
            />
            <span className="checkbox-label">{column.headerName}</span>
          </div>
        );
      case "select":
        let selectedOption = ""
        if(formData[column.field] !== ""){
          selectedOption = formData[column.field] 
          ? column.options.find(option => option.label === formData[column.field])
          : column.options[0]; 
        }

        return (
          <Select
            value={selectedOption}
            onChange={(selectedOption) => handleChange(column.field, selectedOption)}
            options={column.options}
            className="select-control"
            placeholder={`${column.headerName}`}
            isSearchable
          />
        );
      case "currency":
        return (
          <input
            type="text"
            inputMode="numeric"
            // pattern="[0-9]*"
            placeholder={column.headerName}
            value={formatCurrency(formData[column.field])}  // Mostrar el valor formateado
            onChange={(e) => handleChange(column.field, unformatCurrency(e.target.value))}
            className="form-control"
            disabled={column.disabled}
            onFocus={handleFocus}
          />
        );
      case "number":
        return (
          <input
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            placeholder={column.headerName}
            value={formData[column.field]}
            onChange={(e) => handleChange(column.field, e.target.value)}
            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
            className="form-control"
            disabled={column.disabled}
            onFocus={handleFocus}

          />
        );
      case "button":
        return (
          <Button
            className="button"
            onClick={() => { column.onClick(); }}
            placeholder={column.headerName}
          >
            {column.headerName}
          </Button>
        );
      case "task":
        return (
          <textarea
            placeholder={column.headerName}
            value={formData[column.field]}
            onChange={(e) => handleChange(column.field, e.target.value)}
            className="form-control fixed-height-textarea "
            rows="3"
            disabled={column.disabled}
          />
        );
      case "file":
        return (
          <div>
            <input
              type="file"
              accept="image/*"
              capture="environment"
              onChange={(e) => handleFileChange(column.field, e)}
              className="form-control"
              disabled={column.disabled}
            />
            {formData[column.field] && (
              <div className="file-preview">
                {formData[column.field].type.startsWith('image/') && (
                  <img
                    src={URL.createObjectURL(formData[column.field])}
                    alt="Vista previa"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '150px',
                      objectFit: 'contain',
                      borderRadius: '5px'
                    }}
                  />
                )}
                {formData[column.field].type === 'application/pdf' && (
                  <iframe
                    src={URL.createObjectURL(formData[column.field])}
                    title="Vista previa del PDF"
                    style={{
                      width: '300px',
                      height: '100px',
                      border: 'none'
                    }}
                  />
                )}
              </div>
            )}
          </div>
        );
      case "string":
        if (props.inputRefName && column.field === props.inputRefName && props.inputRef) {

          return (
            <input
              id={column.field}
              type={column.type}
              placeholder={column.headerName}
              value={formData[column.field]}
              onChange={(e) => handleChange(column.field, e.target.value)}
              className="form-control"
              disabled={column.disabled}
              onKeyDown={column.onKeyDown}
              onFocus={handleFocus}
              ref={props.inputRef}
            />
          );
        }
        return (
          <input
            id={column.field}
            type={column.type}
            placeholder={column.headerName}
            value={formData[column.field]}
            onChange={(e) => handleChange(column.field, e.target.value)}
            className="form-control"
            disabled={column.disabled}
            onKeyDown={column.onKeyDown}
            onFocus={handleFocus}
          />
        );
      default:
        return (
          <input
            id={column.field}
            type={column.type}
            placeholder={column.headerName}
            value={formData[column.field]}
            onChange={(e) => handleChange(column.field, e.target.value)}
            className="form-control"
            disabled={column.disabled}
            onKeyDown={column.onKeyDown}
            onFocus={handleFocus}

          />
        );
    }
  }
};

export default Form;
