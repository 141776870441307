import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    openMenu: true,
    isMobileDevice: false
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setOpenMenu(state, action) {
            state.openMenu = action.payload;
        },
        setIsMobileDevice(state, action) {
            state.isMobileDevice = action.payload;
        },
        setMenuData(state, action) {
            state.openMenu = action.payload.openMenu;
            state.isMobileDevice = action.payload.isMobileDevice;
        }
    },
});

export const { setOpenMenu, setIsMobileDevice, setMenuData } = menuSlice.actions;
export default menuSlice.reducer;
