import React from 'react';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

export const ExportToExcel = ({ columns, data, fileName }) => {
  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <Button onClick={handleExport} variant="contained" style={{ backgroundColor: '#E040FB' }}>
      <DownloadIcon />
    </Button>
  );
};
