import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

import DataTable from "app/components/dataTable/DataTable";
import Form from "app/components/form/Form";
import CircularProgress from "app/components/circularprogress/CircularProgress";
import { setOpenMenu } from 'app/store/menu/menuSlice';
import { Button, TextField } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useUploadToS3 } from 'app/utils/s3/s3';

import itemService from 'app/services/item/itemService';
import TransactioncountsService from 'app/services/transactioncounts/transactioncountsService'
import TransactionsService from 'app/services/transaction/TransactionService';
import LogService from 'app/services/logs/logService';
import { generateInvoicePdf } from "app/utils/pdf/PDF"
import QrCode2Icon from '@mui/icons-material/QrCode2';
import BarcodeScanner from 'app/components/barcodescanner/barcodeScanner'; // Importamos el componente de escaneo
import "./purchase.scss";

const NewPurchase = () => {
    const dispatch = useDispatch();
    const isMobile = useSelector((state) => state.menu.isMobileDevice);
    const userData = useSelector(state => state.user.user.user);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [datacountnumber, setDatacountnumber] = useState({});
    const [isScannerVisible, setIsScannerVisible] = useState(false);
    const [scannedBarcode, setScannedBarcode] = useState('');
    const [isFetchingItems, setIsFetchingItems] = useState(false);
    const inputRefCode = useRef(null);
    const location = useLocation();
    const payment_methods = [{ "label": 'efectivo', value: "efectivo" }, { "label": 'trasnferencia', value: "trasnferencia" }]
    const uploadToS3 = useUploadToS3();

    const [ordernumber, setOrderNumber] = useState('');
    const [datapurchases, setDatapurchases] = useState({
        "reg_number": "",
        "identification": "",
        "name": "",
        "limit_credit": 0,
        "send_phone": false,
        "mobile": "",
        "subtotal": 0,
        "total": 0,
        "discount": 0,
        "balance_before": 0,
        "balance_after": 0,
        "received": 0,
        "change_given": 0,
        "items": [],
        "create_pdf": false,
        "payment_method":"efectivo"

    });
    const columnsForm = [
        {
            field: "reg_number",
            type: "string",
            headerName: "Factura del proveedor",
            showFormNew: true,
            disabled: false,
            onChange: (e) => { handleChane(e, "reg_number") },
        },

        {
            field: "name",
            type: "string",
            headerName: "Nombre del proveedor",
            showFormNew: true,
            disabled: false,
            onChange: (e) => { handleChane(e, "name") }
        },
        {
            field: "identification",
            type: "string",
            headerName: "NIT del proveedor",
            showFormNew: true,
            disabled: false,
            onChange: (e) => { handleChane(e, "identification") }
        },

    ];

    const columnsFormItems = [
        {
            field: "code",
            type: "string",
            headerName: "Código del producto",
            showFormNew: true,
            disabled: false,
            onKeyDown: (e) => handleEnterKey(e, "item"),
            renderExtra: (value, loadingButton) => {
                return (
                    <div className='buttons-items'>
                        <Button onClick={() => fetchItems(value)} className="button button-search" disabled={loadingButton}>
                            {loadingButton ? (
                                <CircularProgress className="CircularProgress" />
                            ) : (
                                <SearchIcon />
                            )}
                        </Button>

                        {isMobile ? (

                            < Button
                                onClick={() => setIsScannerVisible(!isScannerVisible)}
                                className="button button-search"
                                disabled={loadingButton}
                            >
                                {loadingButton ? (
                                    <CircularProgress className="CircularProgress" />
                                ) : (
                                    <QrCode2Icon className="small-icon" />
                                )}
                            </Button >

                        ) : null}
                    </div>
                );
            }
        }
    ];

    const columnsTable = [

        {
            field: "code",
            type: "string",
            headerName: "Código",
            showTable: true,
            disabled: false,

        },
        {
            field: "name",
            type: "string",
            headerName: "Nombre",
            showTable: true,
            disabled: false,

        },
        {
            field: "cost",
            type: "number",
            headerName: "Costo",
            showTable: true,
            showFormEdit: false,
            disabled: false,
            Cell: ({ value, row, column }) => {
                const [localValue, setLocalValue] = useState(value);
                const inputRef = useRef(null);

                const handleLocalChange = (e) => {
                    const newValue = e.target.value;
                    setLocalValue(newValue);
                };

                const handleBlur = () => {
                    // Solo actualiza el estado global cuando el input pierde el foco
                    requestAnimationFrame(() => {
                        handleEditCell(row.index, column.id, localValue);
                    });
                };

                const handleKeyDown = (e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault(); // Prevenir comportamiento predeterminado de "Enter" (como enviar formularios)
                        // Envía los cambios cuando se presiona Enter
                        handleEditCell(row.index, column.id, localValue);
                        inputRef.current.blur(); // Desenfoca el input para disparar handleBlur si es necesario
                    }
                };

                return (
                    <TextField
                        inputRef={inputRef}
                        value={localValue}
                        onChange={handleLocalChange}
                        onBlur={handleBlur}  // Actualiza el estado global solo al perder el foco
                        onKeyDown={handleKeyDown}  // Maneja la tecla "Enter"
                        variant="outlined"
                        size="small"
                    />
                );
            },
        },
        {
            field: "price",
            type: "number",
            headerName: "Precio",
            showTable: true,
            showFormEdit: false,
            disabled: false,
            Cell: ({ value, row, column }) => {
                const [localValue, setLocalValue] = useState(value);
                const inputRef = useRef(null);

                const handleLocalChange = (e) => {
                    const newValue = e.target.value;
                    setLocalValue(newValue);
                };

                const handleBlur = () => {
                    // Solo actualiza el estado global cuando el input pierde el foco
                    requestAnimationFrame(() => {
                        handleEditCell(row.index, column.id, localValue);
                    });
                };

                const handleKeyDown = (e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault(); // Prevenir comportamiento predeterminado de "Enter" (como enviar formularios)
                        // Envía los cambios cuando se presiona Enter
                        handleEditCell(row.index, column.id, localValue);
                        inputRef.current.blur(); // Desenfoca el input para disparar handleBlur si es necesario
                    }
                };

                return (
                    <TextField
                        inputRef={inputRef}
                        value={localValue}
                        onChange={handleLocalChange}
                        onBlur={handleBlur}  // Actualiza el estado global solo al perder el foco
                        onKeyDown={handleKeyDown}  // Maneja la tecla "Enter"
                        variant="outlined"
                        size="small"
                    />
                );
            },
        },

        {
            field: "quantity",
            type: "number",
            headerName: "Cantidad",
            showTable: true,
            showFormEdit: false,
            disabled: false,
            Cell: ({ value, row, column }) => {
                const [localValue, setLocalValue] = useState(value);
                const inputRef = useRef(null);

                const handleLocalChange = (e) => {
                    const newValue = e.target.value;
                    setLocalValue(newValue);
                };

                const handleBlur = () => {
                    // Solo actualiza el estado global cuando el input pierde el foco
                    requestAnimationFrame(() => {
                        handleEditCell(row.index, column.id, localValue);
                    });
                };

                const handleKeyDown = (e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault(); // Prevenir comportamiento predeterminado de "Enter" (como enviar formularios)
                        // Envía los cambios cuando se presiona Enter
                        handleEditCell(row.index, column.id, localValue);
                        inputRef.current.blur(); // Desenfoca el input para disparar handleBlur si es necesario
                    }
                };

                return (
                    <TextField
                        inputRef={inputRef}
                        value={localValue}
                        onChange={handleLocalChange}
                        onBlur={handleBlur}  // Actualiza el estado global solo al perder el foco
                        onKeyDown={handleKeyDown}  // Maneja la tecla "Enter"
                        variant="outlined"
                        size="small"
                    />
                );
            },
        },
        {
            field: "subtotal",
            type: "number",
            headerName: "Subtotal",
            showTable: true,
            showFormEdit: false,
            disabled: false,
            Cell: ({ value }) => {
                if (typeof value === 'number') {
                    return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 });
                } else {
                    return 0;
                }
            }
        },

        {
            field: "bu",
            type: "string",
            headerName: "",
            width: 70,
            showForm: false,
            showTable: true,
            Cell: ({ row }) => (
                <div className="buttons" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                    <Button
                        className="buttons-deleted"
                        // color="secondary"
                        onClick={() => { handleDeleteRow(row) }}
                    >
                        <DeleteIcon className="small-icon" />
                    </Button>
                </div>
            ),

        }

    ];

    const columnsFormBalance = [
        {
            field: "payment_method",
            type: "select",
            headerName: "Método de pago",
            showFormNew: true,
            disabled: false,
            options: payment_methods
        }
        ,

        {
            field: "create_pdf",
            type: "boolean",
            headerName: "Generar factura de compra",
            showFormNew: true,
            disabled: true,
        },

        {
            field: "total",
            type: "currency",
            headerName: "Total",
            showFormNew: true,
            disabled: true,

        },

        {
            field: "document", // nuevo campo
            type: "file",
            headerName: "Cargar la factura del proveedor",
            showFormNew: true,
            disabled: false,
        }

    ];



    const putOrderNumber = useCallback(async () => {

        try {
            const dataNumber = await TransactioncountsService.getAll();
            setOrderNumber("COM-" + (dataNumber[0]["purchases_consecutive"] + 1).toString().padStart(5, '0'));
            setDatacountnumber({ "id": dataNumber[0]["id"], "field": "purchases_consecutive", "number": dataNumber[0]["purchases_consecutive"] + 1 })
            setIsLoading(false);

        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        if (isMobile) {
            dispatch(setOpenMenu(false));
        }
        const datapurchasesStore = JSON.parse(localStorage.getItem("datapurchases"))
        if (datapurchasesStore) {
            setDatapurchases(datapurchasesStore)
        }

        putOrderNumber()
        localStorage.setItem('lastVisitedPage', location.pathname);
    }, [isMobile, putOrderNumber, dispatch, location]);

    useEffect(() => {
        if (datapurchases.items.length > 0) {
            localStorage.setItem("datapurchases", JSON.stringify(datapurchases));
        }
        else {
            localStorage.removeItem('datapurchases')
        }
    }, [datapurchases]);

    const calculateTotal = useCallback((updatedItems) => {
        let subtotal = 0;
        updatedItems.forEach(item => {
            subtotal += item.subtotal;
        });

        setDatapurchases(prevData => ({
            ...prevData,
            subtotal,
            total: subtotal - datapurchases.discount,
            balance_after: subtotal + datapurchases.balance_before
        }));
    }, [datapurchases.balance_before, datapurchases.discount]);

    useEffect(() => {
        calculateTotal(datapurchases.items);
    }, [datapurchases.items, calculateTotal]);

    const fetchItems = useCallback(async (data) => {

        if (data["code"] === "") {
            setSnackbarMessage('Debe agregar un valor'); // Asegúrate de que setSnackbarMessage reciba un valor adecuado
            setOpenSnackbar(true);
            return
        }
        setLoadingButton(true);
        try {
            const dataItem = await itemService.getAll({ "code": data["code"].trim() });
            if (Array.isArray(dataItem)) {
                // Creamos un nuevo array basado en los items actuales
                const updatedItems = [...datapurchases.items];

                dataItem.forEach(item => {
                    // Busca si el item ya existe en el array basado en el código
                    const existingItemIndex = updatedItems.findIndex(i => i.code === item.code);

                    if (existingItemIndex !== -1) {
                        // Si el item existe, suma la cantidad
                        updatedItems[existingItemIndex].quantity += 1
                        updatedItems[existingItemIndex].subtotal = (updatedItems[existingItemIndex].quantity * updatedItems[existingItemIndex].cost)

                    } else {
                        // Si el item no existe, lo agrega al array
                        const newItem = {
                            code: item.code,
                            quantity: 1, // Cantidad por defecto
                            discount: 0,
                            subtotal: 0,
                            price: item.selling_price,
                            cost: 0,
                            ...item // O cualquier otro campo que quieras incluir
                        };


                        updatedItems.push(newItem);
                    }
                });

                // Actualiza el estado `datapurchases` con el nuevo array de items
                setDatapurchases(prevData => ({
                    ...prevData,
                    items: updatedItems // Reemplazamos el array de `items` con el nuevo array actualizado
                }));

                // Después de procesar los items, fuerza el foco y la selección del input "code"
                if (inputRefCode.current) {
                    inputRefCode.current.focus();  // Forzar el enfoque en el input
                    inputRefCode.current.select(); // Seleccionar el contenido del input
                }
            }
            else {

                setSnackbarMessage('No se encontro ningun dato'); // Asegúrate de que setSnackbarMessage reciba un valor adecuado
                setOpenSnackbar(true);

            }

        } catch (error) {
            console.log(error);
        } finally {

            setLoadingButton(false);
        }
    }, [datapurchases.items]);

    useEffect(() => {
        if (scannedBarcode !== '' && !isFetchingItems) {  // Evita disparar múltiples solicitudes
            const updateCode = async () => {
                setIsFetchingItems(true);  // Marcamos que estamos solicitando los ítems
                try {
                    await fetchItems({ code: scannedBarcode });
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsFetchingItems(false);  // Marcamos que hemos terminado
                    setScannedBarcode('');  // Limpiamos el valor una vez procesado
                }
            };

            updateCode();
        }
    }, [scannedBarcode, fetchItems, isFetchingItems]);


    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleEnterKey = async (e, type) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (e.target.value === "") {
                setSnackbarMessage('Debe agregar un valor'); // Asegúrate de que setSnackbarMessage reciba un valor adecuado
                setOpenSnackbar(true);
                return
            }
            const data = {
                "code": e.target.value,
            }
            await fetchItems(data);

        }
    };

    const handleEditCell = (rowIndex, columnId, value) => {
        setDatapurchases(prevData => {
            const updatedItems = [...prevData.items];
            // Lógica condicional según columnId
            if (columnId === 'quantity') {
                // Si columnId es 'quantity', realiza una acción específica
                const newSubtotal = (Number(value) * updatedItems[rowIndex].cost); // Ejemplo: recalcular subtotal basado en cantidad
                updatedItems[rowIndex] = {
                    ...updatedItems[rowIndex],
                    quantity: Number(value),
                    subtotal: newSubtotal
                };
            }
            else if (columnId === 'cost') {
                // Si columnId es 'quantity', realiza una acción específica
                const newSubtotal = (Number(value) * updatedItems[rowIndex].quantity); // Ejemplo: recalcular subtotal basado en cantidad
                updatedItems[rowIndex] = {
                    ...updatedItems[rowIndex],
                    cost: Number(value),
                    subtotal: newSubtotal
                };

            }

            else if (columnId === 'price') {
                // Si columnId es 'quantity', realiza una acción específica
                const newSubtotal = (updatedItems[rowIndex].quantity * updatedItems[rowIndex].cost); // Ejemplo: recalcular subtotal basado en cantidad
                updatedItems[rowIndex] = {
                    ...updatedItems[rowIndex],
                    price: Number(value),
                    subtotal: newSubtotal
                };

            }
            const newDatapurchases = { ...prevData, items: updatedItems };
            return newDatapurchases;
        });
    };


    const handleChane = (e, field_name) => {
        setDatapurchases(prevState => {
            let updatedData = { ...prevState, [field_name]: e };
            return updatedData;
        });
    };


    const handleDeleteRow = (row) => {
        const updatedItems = [...datapurchases.items];
        updatedItems.splice(row.id, 1);
        // Actualiza el estado `datapurchases` con el nuevo array de items
        setDatapurchases(prevData => ({
            ...prevData,
            items: updatedItems // Reemplazamos el array de `items` con el nuevo array actualizado
        }));
    };


    const submit = async (data) => {

        let payment_method = data['payment_method']

        if (typeof data['payment_method'] === 'object') {
            payment_method = data['payment_method']["value"]
        }


        // Se valida que hayan productos agregados en la venta.
        if (datapurchases["items"].length <= 0) {
            return "No hay datos para registrar"
        }

        // Se valida que hayan productos agregados en la venta.
        if (datapurchases["reg_number"] === "") {
            return "Se debe proporcionar un número de factura de proveedor válido."
        }

        let documentUrl = "";
        if (data.document) {
            documentUrl = await uploadToS3(data.document, datapurchases["reg_number"].toLowerCase(), ordernumber.toLowerCase() );
        }


        // Se valida que todos lo productos tengan el inventario suficiente para la venta
        let updatedItems = [];
        let logs = [];

        for (const item of datapurchases["items"]) {
          
            if (item.cost === undefined || item.quantity === "" || item.cost === "" || item.price === "" || item.quantity <= 0 || item.cost < 0 || item.price <= 0 || item.cost > item.price  ) {
                return "Hay datos erróneos. Por favor, verifica e intenta nuevamente."
            }

            updatedItems.push({
                "updateOne": {
                    "filter": { "_id": item.id },
                    "update": {
                        "$inc": { "stock": item.quantity },  // Incrementa el valor de stock
                        "$set": { 
                            "cost_price": item.cost,         // Establece el valor de cost_price
                            "selling_price": item.price      // Establece el valor de selling_price
                        }
                    }
                }
            });
            

            logs.push({
                "user": userData.username,
                "process": "update",
                "module": "items",
                "code": item.code.toLowerCase(),
                "name": item.name,
                "description": `Se compraron ${item["quantity"]} unidades a un costo de ${item["cost"].toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 })} y con un precio de venta ${item["price"].toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 })} en la transaccion ${ordernumber}.`
            })


        }


        // //  se actualiza los productos con el nuevo inventario
        itemService.put("bulk", { "data_bulk_write": updatedItems }, true);



        const newTrasaction = {
            "number": ordernumber.toLowerCase(),
            "type": "purchases",
            "sub_type": "compras",
            "reg_number": datapurchases["reg_number"].toLowerCase(),
            "items": datapurchases["items"],
            "total": data["total"],
            "user": userData.username.toLowerCase(),
            "received": 0,
            "change_given": 0,
            "discount": 0,
            "subtotal": 0,
            "customer_name": datapurchases["name"].toLowerCase(),
            "identification": datapurchases["identification"].toLowerCase(),
            "mobile": datapurchases["mobile"],
            "status": "cerrada",
            "balance_before": 0,
            "balance_after": 0,
            "payment_method": payment_method,
            "document_url":documentUrl
        }
        const response = await TransactionsService.post(newTrasaction)



        logs.push({
            "user": userData.username,
            "process": "create",
            "module": "transaction",
            "code": ordernumber.toLowerCase(),
            "name": datapurchases.name,
            "description": `Se agregó compra ${ordernumber} con un total de ${data["total"].toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
        })

        let data_count = {};
        data_count[datacountnumber["field"]] = datacountnumber["number"];

        await TransactioncountsService.put(datacountnumber["id"], data_count)

        LogService.postMany(logs)

        localStorage.removeItem('datapurchases');

        if (data.create_pdf) {
            generateInvoicePdf(userData, newTrasaction, "Factura de compra")
        }
        return response
    }
    // Función que maneja el código escaneado
    const handleBarcodeScanned = (barcode) => {
        if (!loadingButton) {  // Evitamos hacer más requests si ya estamos cargando
            setScannedBarcode(barcode); // Esto activa el useEffect
        }
    };

    return (
        <div className="purchase" >
            <div className="info">
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <div className="new-purchase">
                        <div className="information-purchase">
                            <div className="order-number">
                                <input
                                    type="text"
                                    value={ordernumber}
                                    className="form-control"
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="forms-new-purchase">
                            <div className="right">
                                <div className="info-customer">
                                    <h3>Información del proveedor</h3>
                                    <Form
                                        data={datapurchases}
                                        slug=""
                                        columns={columnsForm}
                                        submit={submit}
                                        page="purchases"
                                        action="add"
                                        showLastActivites={false}
                                        loadingButton={loadingButton}
                                        showButtomSubmmit={false}

                                    />
                                </div>
                                <div className="info-items">
                                    <h3>Zona de escaneo</h3>
                                    {isScannerVisible && (
                                        <>
                                            {/* Siempre mantenemos montado el componente BarcodeScanner */}
                                            <BarcodeScanner
                                                setBarcode={(barcode) => {
                                                    if (!loadingButton) {  // Solo procesamos el código si no estamos cargando
                                                        handleBarcodeScanned(barcode);
                                                    }
                                                }}
                                            />

                                            {/* Controlamos la visibilidad del CircularProgress con estilos */}
                                            {loadingButton && (
                                                <div>
                                                    <CircularProgress className="CircularProgress" />
                                                </div>
                                            )}
                                        </>
                                    )}

                                    <Form
                                        slug=""
                                        columns={columnsFormItems}
                                        submit={submit}
                                        page="purchases"
                                        action="add"
                                        showLastActivites={false}
                                        loadingButton={loadingButton}
                                        showButtomSubmmit={false}
                                        inputRef={inputRefCode}
                                        inputRefName="code"

                                    />
                                </div>
                            </div>
                            <div className="left">
                                <h3>Información de la compra</h3>
                                <Form
                                    data={datapurchases}
                                    slug=""
                                    columns={columnsFormBalance}
                                    submit={submit}
                                    page="purchases"
                                    action="add"
                                    showLastActivites={false}
                                    loadingButton={loadingButton}
                                    showButtomSubmmit={true}
                                    inputRef={inputRefCode}
                                    inputRefName="code"
                                />
                            </div>

                        </div>

                        <DataTable slug="customers" columns={columnsTable} rows={datapurchases.items} />

                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackbar}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                            <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%', marginTop: '100px' }}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>

                    </div>
                )}
            </div >
        </div >
    )
}

export default NewPurchase