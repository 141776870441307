import React, { useMemo } from 'react';
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Box, Button, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';

import { ExportToExcel } from './ExportToExcel';
import 'app/components/dataTable/dataTable.scss';


const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <TextField
      value={globalFilter || ''}
      onChange={(e) => setGlobalFilter(e.target.value || undefined)}
      placeholder="Buscar..."
      variant="outlined"
      size="small"
      sx={{ width: '300px' }}
    />
  );
};

const DefaultColumnFilter = ({
  column: { filterValue, setFilter, id },
}) => {
  return (
    <TextField
      className='TextFieldFilter'
      value={filterValue || ''}
      onChange={(e) => setFilter(e.target.value || undefined)}
      placeholder={`Search ${id}`}
      variant="outlined"
      size="small"
      fullWidth
    />
  );
};

const DataTable = (props) => {
  const filteredColumns = useMemo(() => props.columns.filter(column => column.showTable).map(column => ({
    Header: column.headerName,
    accessor: column.field,
    width: column.width,
    ...column
  })), [props.columns]);

  const data = useMemo(() => props.rows, [props.rows]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter, pageIndex, pageSize },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
  } = useTable(
    {
      columns: filteredColumns,
      data,
      initialState: { pageIndex: 0, pageSize: 50 },
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  return (
    <Paper className='Paper' sx={{ padding: '20px', backgroundColor: '#f4f6f8' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        {props.edit ?  <ExportToExcel columns={filteredColumns} data={data} fileName="datos" /> : ""}
      </Box>
      <div className="table-container" {...getTableProps()}>
        <table>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`mirnev-${headerGroup.id}`}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} key={column.id}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {

              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} key={cell.column.id} >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
        <Button variant="contained" style={{ backgroundColor: '#E040FB' }} onClick={() => previousPage()} disabled={!canPreviousPage}>
          <ArrowBackIosIcon />
        </Button>
        <Typography>
          Pagina {' '}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>{' '}
        </Typography>
        <Button variant="contained" style={{ backgroundColor: '#E040FB' }} onClick={() => nextPage()} disabled={!canNextPage}>
          <ArrowForwardIosIcon />
        </Button>
        <Select
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
          displayEmpty
        >
          {[10, 20, 30, 40, 50, 100].map(pageSize => (
            <MenuItem key={pageSize} value={pageSize}>
              {pageSize}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Paper>
  );
};
export default DataTable;