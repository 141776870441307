import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Form from "app/components/form/Form";
import CircularProgress from "app/components/circularprogress/CircularProgress";
import { setOpenMenu } from 'app/store/menu/menuSlice';
import Button from '@mui/material/Button';

import ItemService from 'app/services/item/itemService';
import LogService from 'app/services/logs/logService';
import CategoryService from 'app/services/category/categoryService';
import SubCategoryService from 'app/services/subcategory/subcategoryService';
import ReferenceService from 'app/services/reference/referenceService';
import BrandService from 'app/services/brand/brandService';
import { generateBarcodeAndDownload } from 'app/components/barcode/Barcode'
import BarcodeScanner from 'app/components/barcodescanner/barcodeScanner'; // Importamos el componente de escaneo
import QrCode2Icon from '@mui/icons-material/QrCode2';

import "./item.scss";



const NewItem = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const isMobile = useSelector((state) => state.menu.isMobileDevice);
    const userData = useSelector(state => state.user.user.user);
    const [isLoading, setIsLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [subcategories, SetSubCategories] = useState([]);
    const [references, setReferences] = useState([]);
    const [brands, setBrands] = useState([]);
    const [formData, setFormData] = useState({ "minimum_stock": 1, "manual": true });
    const [scannedBarcode, setScannedBarcode] = useState('');
    const [isScannerVisible, setIsScannerVisible] = useState(false);

    const columns = [
        {
            field: "manual",
            type: "boolean",
            headerName: "Código automático",
            showFormNew: true,
            disabled: false,
            onChange: (value) => {
                setFormData({ ...formData, manual: value });
            }
        },

        {
            field: "code",
            type: "string",
            headerName: "Código",
            showFormNew: true,
            disabled: true,

            renderExtra: (value, loadingButton) => {

                return (
                    isMobile && !formData.manual ? (
                        <>
                            {/* Botón para abrir o cerrar el escáner */}
                            <Button
                                onClick={() => setIsScannerVisible(!isScannerVisible)}
                                className="button button-search"
                                disabled={loadingButton}
                            >
                                {loadingButton ? (
                                    <CircularProgress className="CircularProgress" />
                                ) : (
                                    <QrCode2Icon className="small-icon" />
                                )}
                            </Button>
                        </>
                    ) : null
                );
            }

        },

        {
            field: "category",
            type: "select",
            headerName: "Categoría",
            showFormNew: true,
            disabled: false,
            options: categories,
            onChange: (selectedOption, formData, setFormData) => {


                let value = selectedOption ? selectedOption.value : '';
                let label = selectedOption ? selectedOption.label : '';
                let updatedCode = `${value || ''}${formData.sub_category.value || ''}${formData.reference.value || ''}${formData.brand.value || ''}`.toLowerCase();
                let updatedDescription = `${label || ""} ${formData.sub_category.label || ""} ${formData.reference.label || ""} de ${formData.brand.label || ""}`

                let subcategoryLabel = formData.sub_category && formData.sub_category.label ? formData.sub_category.label.substring(0, 3) : '';
                let referenceLabel = formData.reference && formData.reference.label ? formData.reference.label.substring(0, 3) : '';
                let labelSubstring = label ? label.substring(0, 3) : '';
                let brandLabel = formData.brand && formData.brand.label ? formData.brand.label.substring(0, 3) : '';

                let updatedName = `${labelSubstring}${subcategoryLabel}${referenceLabel}${brandLabel}`.toUpperCase();

                formData.manual ?
                    setFormData({ ...formData, category: { value, label }, code: "20" + updatedCode, name: updatedName, description: updatedDescription }) :
                    setFormData({ ...formData, category: { value, label }, name: updatedName, description: updatedDescription });
            }
        },

        {
            field: "sub_category",
            type: "select",
            headerName: "Sub Categoría",
            showFormNew: true,
            disabled: false,
            options: subcategories,
            onChange: (selectedOption, formData, setFormData) => {
                let value = selectedOption ? selectedOption.value : '';
                let label = selectedOption ? selectedOption.label : '';
                let updatedCode = `${formData.category.value || ''}${value || ''}${formData.reference.value || ''}${formData.brand.value || ''}`.toLowerCase();
                let updatedDescription = `${formData.category.value || ''} ${label || ''} ${formData.reference.value || ''} de ${formData.brand.value || ''}`.toLowerCase();

                let categoryLabel = formData.category && formData.category.label ? formData.category.label.substring(0, 3) : '';
                let referenceLabel = formData.reference && formData.reference.label ? formData.reference.label.substring(0, 3) : '';
                let labelSubstring = label ? label.substring(0, 3) : '';
                let brandLabel = formData.brand && formData.brand.label ? formData.brand.label.substring(0, 3) : '';

                let updatedName = `${categoryLabel}${labelSubstring}${referenceLabel}${brandLabel}`.toUpperCase();

                formData.manual ?
                    setFormData({ ...formData, sub_category: { value, label }, code: "20" + updatedCode, name: updatedName, description: updatedDescription }) :
                    setFormData({ ...formData, sub_category: { value, label }, name: updatedName, description: updatedDescription })

            }
        },
        {
            field: "reference",
            type: "select",
            headerName: "Referencia",
            showFormNew: true,
            disabled: false,
            options: references,
            onChange: (selectedOption, formData, setFormData) => {
                let value = selectedOption ? selectedOption.value : '';
                let label = selectedOption ? selectedOption.label : '';
                let updatedCode = `${formData.category.value || ''}${formData.sub_category.value || ''}${value || ''}${formData.brand.value || ''}`.toLowerCase();
                let updatedDescription = `${formData.category.label || ''}${formData.sub_category.label || ''}${label || ''} de ${formData.brand.label || ''}`.toLowerCase();

                let categoryLabel = formData.category && formData.category.label ? formData.category.label.substring(0, 3) : '';
                let subcategoryLabel = formData.sub_category && formData.sub_category.label ? formData.sub_category.label.substring(0, 3) : '';
                let labelSubstring = label ? label.substring(0, 3) : '';
                let brandLabel = formData.brand && formData.brand.label ? formData.brand.label.substring(0, 3) : '';

                let updatedName = `${categoryLabel}${subcategoryLabel}${labelSubstring}${brandLabel}`.toUpperCase();

                formData.manual ?
                    setFormData({ ...formData, reference: { value, label }, code: "20" + updatedCode, name: updatedName, description: updatedDescription }) :
                    setFormData({ ...formData, reference: { value, label }, name: updatedName, description: updatedDescription })

            }
        },
        {
            field: "brand",
            type: "select",
            headerName: "Marca",
            showFormNew: true,
            disabled: false,
            options: brands,
            onChange: (selectedOption, formData, setFormData) => {
                let value = selectedOption ? selectedOption.value : '';
                let label = selectedOption ? selectedOption.label : '';
                let updatedCode = `${formData.category.value || ''}${formData.sub_category.value || ''}${formData.reference.value || ''}${value || ''}`.toLowerCase();
                let updatedDescription = `${formData.category.label || ''} ${formData.sub_category.label || ''} ${formData.reference.label || ''} de ${label || ''}`.toLowerCase();

                let categoryLabel = formData.category && formData.category.label ? formData.category.label.substring(0, 3) : '';
                let subcategoryLabel = formData.sub_category && formData.sub_category.label ? formData.sub_category.label.substring(0, 3) : '';
                let referenceLabel = formData.reference && formData.reference.label ? formData.reference.label.substring(0, 3) : '';
                let labelSubstring = label ? label.substring(0, 3) : '';

                let updatedName = `${categoryLabel}${subcategoryLabel}${referenceLabel}${labelSubstring}`.toUpperCase();

                formData.manual ?
                    setFormData({ ...formData, brand: { value, label }, code: "20" + updatedCode, name: updatedName, description: updatedDescription }) :
                    setFormData({ ...formData, brand: { value, label }, name: updatedName, description: updatedDescription })

            }
        },
        {
            field: "name",
            type: "string",
            headerName: "Nombre",
            showFormNew: true,
            disabled: false,
        },

        {
            field: "minimum_stock",
            type: "number",
            headerName: "Inventario Inventario ",
            showFormNew: true,
            disabled: false,
        },
        {
            field: "cost_price",
            type: "currency",
            headerName: "Costo",
            showFormNew: true,
            disabled: false,
        },
        {
            field: "selling_price",
            type: "currency",
            headerName: "Precio de venta",
            showFormNew: true,
            disabled: false,
        },
        {
            field: "description",
            type: "task",
            headerName: "Descripción",
            showFormNew: true,
            disabled: false,

        },

    ];

    useEffect(() => {
        setIsLoading(true)
        if (isMobile) {
            dispatch(setOpenMenu(false));
        }
        const uploaderData = async () => {
            try {
                const [categoriesData, subcategoriesData, referencesData, brandsData] = await Promise.all([
                    CategoryService.getAll(),
                    SubCategoryService.getAll(),
                    ReferenceService.getAll(),
                    BrandService.getAll()
                ]);

                if (Array.isArray(categoriesData)) {
                    setCategories(categoriesData);
                }
                if (Array.isArray(subcategoriesData)) {
                    SetSubCategories(subcategoriesData);
                }
                if (Array.isArray(referencesData)) {
                    setReferences(referencesData);
                }
                if (Array.isArray(brandsData)) {
                    setBrands(brandsData);
                }
            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setIsLoading(false);
            }
        };
        localStorage.setItem('lastVisitedPage', location.pathname);
        uploaderData();

    }, [isMobile, location.pathname, dispatch]);


    useEffect(() => {
        setIsScannerVisible(false)
        if (scannedBarcode) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                code: scannedBarcode,
            }));
        }

    }, [scannedBarcode]);

    const submit = async (data) => {
        const costPrice = parseFloat(data.cost_price);
        const sellingPrice = parseFloat(data.selling_price);

        if(costPrice > sellingPrice){
            return "El precio de venta no puede ser menor al costo"
        }


        data.category = data.category.label
        data.sub_category = data.sub_category.label
        data.reference = data.reference.label
        data.brand = data.brand.label
        data.stock = 0
        data.url_image = "https://logosmirnev.s3.sa-east-1.amazonaws.com/box.jpeg"

        try {
            const response = await ItemService.post(data)
            if (response.status === 200) {
                if (data.manual) {
                    generateBarcodeAndDownload(data.code)
                }

              
                await LogService.post({
                    "user": userData.username,
                    "process": "create",
                    "module": "items",
                    "code": data.code,
                    "name": data.name,
                    "description": `Se agregó el producto ${data.name.toUpperCase()} (${data.code.toUpperCase()}) con un inventario de ${data.stock}, un costo de ${costPrice.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 })} y un precio de venta de ${sellingPrice.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 })}`

                })
                return response
            }
            return response
        } catch (error) {
            return error
        }
    }

    return (
        <div className="" >
            <div className="info">
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <div className="">
                        {isScannerVisible && (
                            <BarcodeScanner
                                setBarcode={(barcode) => {
                                    setScannedBarcode(barcode)
                                }}

                            />
                        )}
                        <Form
                            slug="producto"
                            columns={columns}
                            submit={submit}
                            page="items"
                            action="add"
                            showLastActivites={false}
                            showButtomSubmmit={true}
                            data={formData}
                        />

                    </div>


                )}



            </div >

        </div >
    )
}

export default NewItem