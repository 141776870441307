import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Button } from '@mui/material';
import DataTable from "app/components/dataTable/DataTable";
import LoadingCircularProgress from "app/components/circularprogress/CircularProgress";
import Snackbar from '@mui/material/Snackbar';

import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentIcon from '@mui/icons-material/Payment';

import TransactionsService from 'app/services/transaction/TransactionService';

import { setOpenMenu } from 'app/store/menu/menuSlice';
import { generateInvoicePdf } from "app/utils/pdf/PDF"

import "./payments.scss";

const Payments = () => {

    const dispatch = useDispatch();
    const isMobile = useSelector((state) => state.menu.isMobileDevice);
    const [transaction, setTransaction] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const userData = useSelector(state => state.user.user.user);
    const [openSnackbar, setOpenSnackbar] = useState(false);


    const columns = [
        {
            field: "number",
            type: "string",
            headerName: "Factura",
            showTable: true,
            Cell: ({ value }) => {
                if (typeof value === 'string' && value.length > 0) {
                    return value.toUpperCase();
                } else {
                    return '';
                }
            }
        },
        {
            field: "sub_type",
            type: "string",
            headerName: "Transaccion",
            showTable: true,
        },
        {
            field: "reg_number",
            type: "string",
            headerName: "# de Cuenta",
            showTable: true,
            Cell: ({ value }) => {
                if (typeof value === 'string' && value.length > 0) {
                    return value.toUpperCase();
                } else {
                    return '';
                }
            }
        },
        {
            field: "customer_name",
            type: "string",
            headerName: "Nombre",
            showTable: true,
        },
        {
            field: "balance_before",
            type: "number",
            headerName: "Antes",
            showTable: true,
            Cell: ({ value }) => {
                if (typeof value === 'number') {
                    return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 });
                } else {
                    return 0;
                }
            }
        }
        ,
        {
            field: "total",
            type: "number",
            headerName: "Pago",
            showTable: true,
            Cell: ({ value }) => {
                if (typeof value === 'number') {
                    return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 });
                } else {
                    return 0;
                }
            }
        }
        ,
        {
            field: "balance_after",
            type: "number",
            headerName: "Despues",
            showTable: true,
            Cell: ({ value }) => {
                if (typeof value === 'number') {
                    return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 });
                } else {
                    return 0;
                }
            }
        },
        {
            field: "user",
            type: "string",
            headerName: "Usuario",
            showTable: true,
            Cell: ({ value }) => {
                if (typeof value === 'string' && value.length > 0) {
                    return value.charAt(0).toUpperCase() + value.slice(1);
                } else {
                    return '';
                }
            }
        },

        {
            field: "creation_date",
            type: "datetime",
            headerName: "Fecha",
            showTable: true,

        },
        {
            field: "bu",
            type: "string",
            headerName: "",
            showTable: true,
            Cell: ({ row }) => (
                <div className="buttons" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                    <Button
                        className="buttons-edit"
                        color="secondary"
                        onClick={() => {
                            generateInvoicePdf(userData, row.original, "Factura de pago");
                        }}
                    >
                        <ReceiptIcon className="small-icon" />
                    </Button>

                </div>
            ),

        }

    ];

    useEffect(() => {
        setIsLoading(true)
        if (isMobile) {
            dispatch(setOpenMenu(false));
        }
        const fetchtransaction = async () => {

            try {
                const dataTransaction = await TransactionsService.getAll({ "type": "payment", "limit": 2000, "sort_field": "creation_date" });
                if (Array.isArray(dataTransaction)) {
                    setTransaction(dataTransaction);
                }
                else {
                    // Si no es un array, muestra un mensaje de error o maneja el caso
                    console.error("La respuesta no es un array:", dataTransaction);
                    setTransaction([]);  // Maneja la falta de datos de forma segura
                }
                setIsLoading(false)
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchtransaction();
    }, [isMobile, dispatch]);


    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <div className="sales">
            <div className="info">
                <h1>Pagos</h1>
                {userData.payments.create ?
                    <Link to={"/payments/new"} className='button'>
                        <PaymentIcon />
                    </Link>

                    :
                    ""
                }
            </div>
            {isLoading ? (
                <LoadingCircularProgress />
            ) : (
                <DataTable slug="sales" columns={columns} rows={transaction} edit={userData.sales.update} delete={userData.sales.delete} />
            )}



            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >

            </Snackbar>

        </div>
    );
};

export default Payments;
