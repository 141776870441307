// src/pages/LoginPage.js
import React from 'react';
import LoginForm from '../../components/login/LoginForm';
import { Container, CssBaseline, Box } from '@mui/material';

const LoginPage = () => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <LoginForm />
      </Box>
    </Container>
  );
};

export default LoginPage;
