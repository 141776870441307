import axios from 'axios';
import { BACKEND_URL } from "config";
import authService from 'app/services/auth/authService';

class ReferenceService {

    getAll = async () => {
        const dataAccessToken = authService.getDataLocalStorage();
        const accessToken = dataAccessToken.accessToken;
        try {
            const headers = {
                "Authorization": `Bearer ${accessToken}`
            };
            const response = await axios.get(`${BACKEND_URL}/reference/`, { headers });
            return response.data;
        } catch (error) {
            if (error.response) {
                const status = error.response.status;
                const detail = error.response.data.detail;

                if (status === 401) {
                    console.error('Error 401: No autorizado. Redirigiendo al inicio de sesión...');
                    authService.emit('onAutoLogout');
                    return null;
                }

                console.error('Error en la solicitud:', detail || 'No se proporcionaron detalles del error');
                return detail || 'Error desconocido';
            } else {
                console.error('Error en la solicitud:', error.message || 'Error desconocido');
                return error.message || 'Error desconocido';
            }
        }
    }


}
const instance = new ReferenceService();
export default instance;