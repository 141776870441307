import React from 'react';
import './NoAccessPage.scss';


const NoAccessPage = () => {
  return (
    <div className='no-access-container'>
      No tienes acceso a esta página.
    </div>
  );
};

export default NoAccessPage;
