import React from 'react';
import './CircularProgress.scss';
import { CircularProgress, Box } from '@material-ui/core';

const LoadingCircularProgress = () => {
  return (
    <Box
      className='CircularProgress'>
      <CircularProgress style={{ color: '#E040FB' }} />
    </Box>
  )
};

export default LoadingCircularProgress;