import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import authService from 'app/services/auth/authService';

import { setOpenMenu } from '../../store/menu/menuSlice';

import "./navbar.scss";


const Navbar = ({ user }) => {
  const dispatch = useDispatch();
  const [dataUser, setDataUser] = useState(user);
  const menuOpen = useSelector((state) => state.menu.openMenu);
  const [settingopen, setSettingOpen] = useState(false);
  const menuRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    if (user && user.user) {
      setDataUser(user.user.user);
    }
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setSettingOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, iconRef]);

  const handleSettingsClick = (event) => {
    event.stopPropagation();
    setSettingOpen(prev => !prev);
  };

  const closeSession = () => {
    authService.emit('onAutoLogout');
  };

  const toggleMenu = () => {
    dispatch(setOpenMenu(!menuOpen));
  };

  return (
    <div className={`navbar ${menuOpen ? "" : "menuOpen"}`}>
      <div className="hamburger-icon" onClick={toggleMenu}>
        <ArrowForwardIosIcon/>
      </div>
      <div className="icons">
        <img src="/search.svg" alt="" className="icon" />
        <img src="/app.svg" alt="" className="icon" />
        <img src="/expand.svg" alt="" className="icon" />
        <div className="notification">
          <img src="/notifications.svg" alt="" />
          <span>1</span>
        </div>
        <div className="user">
          <img
            src="https://images.pexels.com/photos/11038549/pexels-photo-11038549.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load"
            alt=""
          />
          <span>{dataUser.username}</span>
        </div>
        <img
          src="/settings.svg"
          alt=""
          className="icon"
          onClick={handleSettingsClick}
          ref={iconRef}
        />
      </div>
      {settingopen && (
        <div className="menu-settings" ref={menuRef}>
          <ul>
            <li>Mi perfil</li>
            <li>Configuración</li>
            <li onClick={closeSession}>Cerrar sesión</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navbar;
