import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatCurrency(value) {
  return value.toLocaleString('es-CO', {
    style: 'currency',
    currency: 'COP',
  });
}

const options = {
  timeZone: 'America/Bogota',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

const optionsHora = {
  timeZone: 'America/Bogota',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};



export const generateInvoicePdf = async (
  userData,
  data,
  title,
) => {


  let date = new Date();
  let finalDate = new Intl.DateTimeFormat('es-CO', options).format(date);
  let horaColombia = new Intl.DateTimeFormat('es-CO', optionsHora).format(date);
  const [day, month, year] = finalDate.split('/');
  let fechaColombia = `${year}-${month}-${day}`;

  if (data.hasOwnProperty("creation_date")) {
    // Separar la fecha y la hora
    const [fecha, timePart] = data["creation_date"].split('T');
    fechaColombia = fecha;

    // Obtener solo la parte de la hora (sin los milisegundos y la zona horaria)
    const [time] = timePart.split('.');  // Separamos usando el '.' para eliminar los milisegundos

    // Convertir la hora al formato de 12 horas con a. m./p. m.
    const [hours, minutes, seconds] = time.split(':');
    let hours12 = parseInt(hours, 10);
    const ampm = hours12 >= 12 ? 'p. m.' : 'a. m.';
    hours12 = hours12 % 12 || 12; // Convertir "0" a "12" para el formato de 12 horas

    // Formatear la hora con dos dígitos para horas, minutos y segundos
    const formattedTime = `${String(hours12).padStart(2, '0')}:${minutes}:${String(seconds).padStart(2, '0')} ${ampm}`;

    horaColombia = formattedTime;
  }

  const doc = new jsPDF();
  const pageHeight = doc.internal.pageSize.getHeight();
  const margin = 15;
  let yPosition = 25;

  const checkAddPage = (doc, yPosition, spaceRequired = 20) => {
    if (yPosition + spaceRequired > pageHeight - margin) {
      doc.addPage();
      return margin;
    }
    return yPosition;
  };

  try {
    const pageWidth = doc.internal.pageSize.getWidth();
    const contentWidth = pageWidth - margin * 2;

    // Configuración de estilos
    const primaryColor = [102, 51, 153];
    const textColor = [51, 51, 51];
    const lightGray = [245, 245, 245];
    const highlightColor = [230, 230, 250];
    const discountColor = [220, 53, 69];
    const fontRegular = 'Helvetica';
    const fontBold = 'Helvetica';

    // Nombre de la empresa con estilo
    doc.setFont(fontBold, 'bold');
    doc.setFontSize(22);
    doc.setTextColor(...primaryColor);
    doc.text(capitalizeFirstLetter(userData.companyname), margin, yPosition);

    // Información de la empresa
    doc.setFontSize(9);
    doc.setTextColor(...textColor);
    yPosition += 5;

    // Dirección
    doc.setFont(fontBold, 'bold');
    doc.text('Dirección:', margin, yPosition);
    doc.setFont(fontRegular, 'normal');
    doc.text(
      ` ${userData.address} `,
      margin + doc.getTextWidth('Dirección: '),
      yPosition
    );

    yPosition = checkAddPage(doc, yPosition);
    yPosition += 4;

    // Teléfono
    doc.setFont(fontBold, 'bold');
    doc.text('Teléfono:', margin, yPosition);
    doc.setFont(fontRegular, 'normal');
    doc.text(
      ` ${userData.phone} `,
      margin + doc.getTextWidth('Teléfono: '),
      yPosition
    );

    yPosition = checkAddPage(doc, yPosition);
    yPosition += 4;

    // Correo
    doc.setFont(fontBold, 'bold');
    doc.text('Correo:', margin, yPosition);
    doc.setFont(fontRegular, 'normal');
    doc.text(
      ` ${userData.email_company} `,
      margin + doc.getTextWidth('Correo: '),
      yPosition
    );

    yPosition = checkAddPage(doc, yPosition);
    yPosition += 4;

    // nit
    doc.setFont(fontBold, 'bold');
    doc.text('NIT:', margin, yPosition);
    doc.setFont(fontRegular, 'normal');
    doc.text(
      ` ${userData.nit} `,
      margin + doc.getTextWidth('NIT:'),
      yPosition
    );

    yPosition = checkAddPage(doc, yPosition);
    yPosition += 7;

    // Línea separadora
    doc.setDrawColor(...primaryColor);
    doc.setLineWidth(1);
    doc.line(margin, yPosition, pageWidth - margin, yPosition);

    yPosition = checkAddPage(doc, yPosition);
    yPosition += 13;

    // Título de la factura
    doc.setFont(fontBold, 'bold');
    doc.setFontSize(16);
    doc.setTextColor(...primaryColor);
    doc.text(title.toUpperCase(), margin, yPosition);

    const invoiceNumberX = pageWidth - margin - 60;
    doc.setFontSize(12);
    doc.setFont(fontBold, 'bold');
    doc.setTextColor(255, 255, 255);
    doc.setFillColor(...primaryColor);
    doc.roundedRect(invoiceNumberX + 15, yPosition - 10, 45, 15, 3, 3, 'F');
    doc.text(
      `N° ${data.number.toUpperCase()}`,
      invoiceNumberX + 35,
      yPosition,
      { align: 'center' }
    );

    yPosition = checkAddPage(doc, yPosition);
    yPosition += 10;


    if (data.customer_name && !data.identification) {
      doc.setFont(fontBold, 'bold');
      doc.setFontSize(12);
      doc.setTextColor(...primaryColor);
      doc.text('Detalles del Cliente', margin, yPosition);
      yPosition = checkAddPage(doc, yPosition);
      yPosition += 7;
      doc.setFontSize(9);
      doc.setTextColor(...textColor);
      doc.setFont(fontBold, 'bold');
      doc.text('Nombre:', margin, yPosition);
      doc.setFont(fontRegular, 'normal');
      const startX = margin + doc.getTextWidth('Nombre: ') + 2;
      const nameText = doc.splitTextToSize(
        data.customer_name,
        contentWidth - doc.getTextWidth('Nombre: ') - 2
      );
      doc.text(nameText, startX, yPosition);
    }

    if (data.reg_number) {
      doc.setFont(fontBold, 'bold');
      doc.setFontSize(12);
      doc.setTextColor(...primaryColor);
      doc.text('Detalles del Cliente', margin, yPosition);

      yPosition = checkAddPage(doc, yPosition);
      yPosition += 7;

      doc.setFontSize(9);
      doc.setTextColor(...textColor);

      doc.setFont(fontBold, 'bold');
      doc.text('Número de Cuenta:', margin, yPosition);
      doc.setFont(fontRegular, 'normal');
      doc.text(
        `${data.reg_number.toUpperCase()}`,
        margin + doc.getTextWidth('Número de Cuenta: ') + 2,
        yPosition
      );

      yPosition = checkAddPage(doc, yPosition);
      yPosition += 5;

      doc.setFont(fontBold, 'bold');
      doc.text('Nombre:', margin, yPosition);
      doc.setFont(fontRegular, 'normal');
      const startX = margin + doc.getTextWidth('Nombre: ') + 2;
      const nameText = doc.splitTextToSize(
        data.customer_name,
        contentWidth - doc.getTextWidth('Nombre: ') - 2
      );
      doc.text(nameText, startX, yPosition);
      yPosition += 5 * nameText.length;

      yPosition = checkAddPage(doc, yPosition);

      doc.setFont(fontBold, 'bold');
      doc.text('Identificación:', margin, yPosition);
      doc.setFont(fontRegular, 'normal');
      doc.text(
        `${data.identification}`,
        margin + doc.getTextWidth('Identificación: ') + 2,
        yPosition
      );

      yPosition = checkAddPage(doc, yPosition);
      yPosition += 5;

      doc.setFont(fontBold, 'bold');
      doc.text('Número de Celular:', margin, yPosition);
      doc.setFont(fontRegular, 'normal');
      doc.text(
        `${data.mobile}`,
        margin + doc.getTextWidth('Número de Celular: ') + 2,
        yPosition
      );

      yPosition = checkAddPage(doc, yPosition);
      yPosition += 7;
    }

    let yPositionFactura = 75;
    doc.setFont(fontBold, 'bold');
    doc.setFontSize(12);
    doc.setTextColor(...primaryColor);
    doc.text('Detalles de la Factura', pageWidth - margin, yPositionFactura, {
      align: 'right',
    });

    yPositionFactura += 7;
    doc.setFontSize(9);
    doc.setTextColor(...textColor);

    doc.setFont(fontBold, 'bold');
    doc.text('Fecha:', pageWidth - margin - 43, yPositionFactura, {
      align: 'left',
    });
    doc.setFont(fontRegular, 'normal');
    doc.text(`${fechaColombia}`, pageWidth - margin - 5, yPositionFactura, {
      align: 'right',
    });

    yPositionFactura += 5;
    doc.setFont(fontBold, 'bold');
    doc.text('Hora:', pageWidth - margin - 43, yPositionFactura, {
      align: 'left',
    });
    doc.setFont(fontRegular, 'normal');
    doc.text(`${horaColombia}`, pageWidth - margin, yPositionFactura, {
      align: 'right',
    });

    yPositionFactura += 5;
    doc.setFont(fontBold, 'bold');
    doc.text('Atendido por:', pageWidth - margin - 43, yPositionFactura, {
      align: 'left',
    });
    doc.setFont(fontRegular, 'normal');
    doc.text(
      `${capitalizeFirstLetter(data.user)}`,
      pageWidth - margin - 11,
      yPositionFactura,
      { align: 'right' }
    );

    yPosition = Math.max(yPosition, yPositionFactura) + 5;
    doc.setDrawColor(...primaryColor);
    doc.setLineWidth(0.5);
    doc.line(margin, yPosition, pageWidth - margin, yPosition);

    yPosition = checkAddPage(doc, yPosition);
    yPosition += 10;

    if (data.balance_before > 0) {
      const accountInfoHeight = 54;
      doc.setFillColor(...highlightColor);
      doc.roundedRect(
        margin,
        yPosition - 5,
        pageWidth - margin * 2,
        accountInfoHeight,
        5,
        5,
        'F'
      );

      doc.setFont(fontBold, 'bold');
      doc.setFontSize(15);
      doc.setTextColor(...primaryColor);
      doc.text(`Información de la cuenta ${data.reg_number.toUpperCase()}`, margin + 10, yPosition + 7);

      yPosition = checkAddPage(doc, yPosition);
      yPosition += 15;

      doc.setFontSize(9);
      doc.setTextColor(...textColor);

      doc.setFont(fontBold, 'bold');
      doc.setFontSize(13);
      doc.text('Saldo Anterior:', margin + 10, yPosition);
      doc.setFont(fontRegular, 'normal');

      doc.text(
        `${formatCurrency(data.balance_before)}`,
        pageWidth - margin - 10,
        yPosition,
        { align: 'right' }
      );

      yPosition = checkAddPage(doc, yPosition);
      yPosition += 7;

      doc.setFont(fontBold, 'bold');
      if (title.includes("pago")) {
        doc.text('Valor del pago:', margin + 10, yPosition);
        doc.setFont(fontRegular, 'normal');
        doc.setFontSize(13);
        doc.text(
          `${formatCurrency(Number(data.total)) || 0
          }`,
          pageWidth - margin - 10,
          yPosition,
          { align: 'right' }
        );
      }
      else {
        doc.text('Total de la venta:', margin + 10, yPosition);
        doc.setFont(fontRegular, 'normal');
        doc.setFontSize(13);
        doc.text(
          `${formatCurrency(Number(data.subtotal)) || 0}`,
          pageWidth - margin - 10,
          yPosition,
          { align: 'right' }
        );
      }

      yPosition = checkAddPage(doc, yPosition);
      yPosition += 13;

      const saldoRectMargin = margin + 10;
      const saldoRectWidth = pageWidth - saldoRectMargin * 2;
      const saldoActualY = yPosition - 5;
      doc.setFillColor(...primaryColor);
      doc.rect(
        saldoRectMargin,
        saldoActualY,
        saldoRectWidth,
        14,
        'F'
      );

      doc.setFont(fontBold, 'bold');
      doc.setFontSize(15);
      doc.setTextColor(255, 255, 255);
      doc.text(
        `Saldo Actual: ${formatCurrency(data.balance_after)}`,
        pageWidth / 2,
        saldoActualY + 9,
        { align: 'center' }
      );

      yPosition = saldoActualY + 23;

      doc.setDrawColor(...primaryColor);
      doc.setLineWidth(0.5);
      doc.line(margin, yPosition, pageWidth - margin, yPosition);

      yPosition = checkAddPage(doc, yPosition);
    }

    if (data.items && data.items.length > 0) {
      yPosition += 10;
      doc.setFont(fontBold, 'bold');
      doc.setFontSize(14);
      doc.setTextColor(...primaryColor);
      doc.text('Detalles', pageWidth / 2, yPosition, {
        align: 'center',
      });

      yPosition = checkAddPage(doc, yPosition);
      yPosition += 5;

      const columnProportions = {
        0: 0.15,
        1: 0.20,
        2: 0.25,
        3: 0.10,
        4: 0.15,
        5: 0.15,
      };

      const columnWidths = Object.keys(columnProportions).reduce((acc, key) => {
        acc[key] = contentWidth * columnProportions[key];
        return acc;
      }, {});

      doc.autoTable({
        startY: yPosition,
        head: [
          ['Código', 'Nombre', 'Descripción', 'Cant.', 'Precio Unitario', 'SubTotal'],
        ],
        styles: {
          font: fontRegular,
          fontSize: 10,
          textColor: textColor,
          overflow: 'linebreak',
          cellPadding: 3,
        },
        headStyles: {
          fillColor: primaryColor,
          textColor: [255, 255, 255],
          fontStyle: 'bold',
          halign: 'center',
        },
        body: data.items.map((item) => [
          item.code,
          item.name,
          item.description,
          item.quantity.toString(),
          formatCurrency(item.price),
          formatCurrency(item.quantity * item.price),
        ]),
        bodyStyles: {
          fillColor: [255, 255, 255],
          halign: 'center',
        },
        alternateRowStyles: {
          fillColor: lightGray,
        },
        columnStyles: {
          0: { cellWidth: columnWidths[0], halign: 'center' },
          1: { cellWidth: columnWidths[1], halign: 'left' },
          2: { cellWidth: columnWidths[2], halign: 'left' },
          3: { cellWidth: columnWidths[3], halign: 'center' },
          4: { cellWidth: columnWidths[4], halign: 'right' },
          5: { cellWidth: columnWidths[5], halign: 'right' },
        },
        margin: { left: margin, right: margin },
        theme: 'striped',
        tableWidth: 'auto',
      });

      yPosition = doc.lastAutoTable.finalY + 5;
      doc.setDrawColor(...primaryColor);
      doc.setLineWidth(0.5);
      doc.line(margin, yPosition, pageWidth - margin, yPosition);

      yPosition = checkAddPage(doc, yPosition);
    }

    yPosition += 10;
    doc.setFontSize(11);
    doc.setTextColor(...textColor);

    if (data.discount && Number(data.discount) > 0) {
      doc.setFont(fontBold, 'bold');
      doc.text('Subtotal:', pageWidth - margin - 50, yPosition);
      doc.setFont(fontRegular, 'normal');
      doc.text(formatCurrency(Number(data.subtotal) || 0), pageWidth - margin, yPosition, {
        align: 'right',
      });

      yPosition = checkAddPage(doc, yPosition);
      yPosition += 5;
      doc.setFont(fontBold, 'bold');
      doc.text('Descuento:', pageWidth - margin - 50, yPosition);
      doc.setFont(fontRegular, 'normal');
      doc.setTextColor(...discountColor);
      doc.text(
        `-${formatCurrency(Number(data.discount))}`,
        pageWidth - margin,
        yPosition,
        { align: 'right' }
      );
      doc.setTextColor(...textColor);
    }

    yPosition = checkAddPage(doc, yPosition);
    yPosition += 10;

    const rectX = pageWidth - margin - 60;
    const rectY = yPosition - 7;
    const rectWidth = 60;
    const rectHeight = 14;

    if (title.includes("venta")) {
      doc.setFillColor(...primaryColor);
      doc.roundedRect(rectX, rectY, rectWidth, rectHeight, 3, 3, 'F');
      doc.setTextColor(255, 255, 255);
      doc.setFont(fontBold, 'bold');
      doc.setFontSize(13);
      doc.text('Total:', rectX + 5, yPosition + 2);
      doc.setFont(fontRegular, 'normal');
      doc.text(
        formatCurrency(Number(data.total) || 0),
        pageWidth - margin - 5,
        yPosition + 2,
        { align: 'right' }
      );

      yPosition = checkAddPage(doc, yPosition);
    }
    if (data.payment_method !== "credito") {
      yPosition += 25;
      const paymentInfoHeight = 40;
      doc.setFillColor(...highlightColor);
      doc.roundedRect(
        margin,
        yPosition - 10,
        pageWidth - margin * 2,
        paymentInfoHeight,
        5,
        5,
        'F'
      );
      doc.setFont(fontBold, 'bold');
      doc.setFontSize(13);
      doc.setTextColor(...primaryColor);
      doc.text('Detalles del Pago', margin + 10, yPosition);

      yPosition = checkAddPage(doc, yPosition);
      yPosition += 7;
      doc.setFontSize(11);
      doc.setTextColor(...textColor);

      doc.setFont(fontBold, 'bold');
      doc.text('Método de Pago:', margin + 10, yPosition);
      doc.setFont(fontRegular, 'normal');
      doc.text(
        capitalizeFirstLetter(data.payment_method),
        margin + 65,
        yPosition,
        { align: 'right' }
      );

      yPosition = checkAddPage(doc, yPosition);
      yPosition += 5;
    }

    if (Number(data.received) > 0) {
      doc.setFont(fontBold, 'bold');
      doc.text('Recibido:', margin + 10, yPosition);
      doc.setFont(fontRegular, 'normal');
      doc.text(
        `${formatCurrency(Number(data.received) || 0)}`,
        margin + 53,
        yPosition,
        { align: 'right' }
      );

      yPosition = checkAddPage(doc, yPosition);
      yPosition += 5;

      doc.setFont(fontBold, 'bold');
      doc.text('Cambio :', margin + 10, yPosition);
      doc.setFont(fontRegular, 'normal');
      doc.text(
        `${formatCurrency(data.change_given || 0)}`,
        margin + 53,
        yPosition,
        { align: 'right' }
      );

      yPosition = checkAddPage(doc, yPosition);
    }

    for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
      doc.setPage(i);
      const footerY = pageHeight - margin / 2;

      doc.setFontSize(7);
      doc.setTextColor(...textColor);
      doc.text(
        'Gracias por su compra. ¡Vuelva pronto!',
        margin,
        footerY,
        { align: 'left' }
      );

      if (data.hasOwnProperty("creation_date")) {

        doc.text(
          'copia ',
          margin + 90,
          footerY,
          { align: 'center' }
        )
      }


      doc.text(
        `Página ${i}`,
        pageWidth - margin,
        footerY,
        { align: 'right' }
      );
    }

    doc.save(`${data.number.toUpperCase()}.pdf`);
  } catch (error) {
    console.error('Error al generar el PDF:', error);
  }
};
