// store.js

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import menuReducer from './menu/menuSlice';


const store = configureStore({
  reducer: {
    user: userReducer,
    menu: menuReducer,
    // Otros reducers aquí si los tienes
  },
});

export default store;
