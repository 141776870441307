import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import authService from 'app/services/auth/authService';

import { logoutUser, setUserData } from '../../store/user/userSlice';

import Loading from '../loading/Loading';
import LoginPage from '../../pages/login/LoginPage';

const Auth = ({ children }) => {
    const [waitAuthCheck, setWaitAuthCheck] = useState(true);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    const dispatch = useDispatch();
    const dispatchAction = useCallback((action) => dispatch(action), [dispatch]);
    const navigate = useNavigate();

    useEffect(() => {
        const jwtCheck = async () => {
            const handleIsAuthTokenValid = () => {
                authService
                    .isAuthTokenValid()
                    .then(user => {
                        dispatchAction(setUserData(user));
                        setWaitAuthCheck(false);
                    })
                    .catch(error => {
                        console.error(error);
                        dispatchAction(logoutUser());
                        setWaitAuthCheck(false);
                    });
            };

            const handleOnAutoLogin = () => {
                const lastVisitedPage = localStorage.getItem('lastVisitedPage');
                if (lastVisitedPage) {
                    navigate(lastVisitedPage);
                } else {
                    navigate('/');
                }
                authService
                    .signInWithToken()
                    .then(user => {
                        dispatchAction(setUserData(user));
                        setWaitAuthCheck(false);
                    })
                    .catch(error => {
                        console.error("onAutoLogin error", error);
                        dispatchAction(logoutUser());
                        setWaitAuthCheck(false);
                    });
            };

            const handleOnAutoLogout = () => {
                authService
                    .logout()
                    .then(() => {
                        dispatchAction(logoutUser());
                        setWaitAuthCheck(false);
                    })
                    .catch(error => {
                        console.error(error);
                        dispatchAction(logoutUser());
                        setWaitAuthCheck(false);
                    });
            };

            const handleOnNoAccessToken = () => {
                setWaitAuthCheck(false);
                dispatchAction(logoutUser());
            };

            authService.on('isAuthTokenValid', handleIsAuthTokenValid);
            authService.on('onAutoLogin', handleOnAutoLogin);
            authService.on('onAutoLogout', handleOnAutoLogout);
            authService.on('onNoAccessToken', handleOnNoAccessToken);

            authService.init();

            return () => {
                // Clean up event listeners
                authService.off('isAuthTokenValid', handleIsAuthTokenValid);
                authService.off('onAutoLogin', handleOnAutoLogin);
                authService.off('onAutoLogout', handleOnAutoLogout);
                // authService.off('onNoAccessToken', handleOnNoAccessToken);
            };
        };

        jwtCheck();

    }, [dispatchAction, waitAuthCheck, navigate]);

    if (waitAuthCheck) {
        return <Loading />;
    }
    return isAuthenticated ? children : <LoginPage />;
};

export default Auth;
